import { useStateMachine } from 'little-state-machine';
import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EmptyConfirm } from './Confirm';
import { EmptyDateTime } from './DateTime';
import { EmptyForms } from './Forms';
import { EmptyLocation } from './Location';
import { EmptyService } from './Service';
import updateAction from '../../lib/updateAction';
import clearAction from '../../lib/clearAction';
import axios from 'axios';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';


export const ConnectForm = ({ children }) => {
    const methods = useFormContext();

    return children({ ...methods });
};


function Checkout() {

    const auth = useAuthUser()


    const [session, setSession] = useState(false);
    const [location, setLocation] = useState(false);
    const navigate = useNavigate();
    const { actions, state } = useStateMachine({ clearAction, updateAction });
    const [cc, setCc] = useState(false);

    const methods = useForm();
    const [subtotal, setSubtotal] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState(0);
    const [checkoutSubmitted, setCheckoutSubmitted] = useState(false);

    const [timeslots, setTimeslots] = useState(false);
    const [checkoutinfo, setCheckoutinfo] = useState(false);

    const [checkoutTaxInfo, setCheckoutTaxInfo] = useState(false);

    const estimatedtax = 10.00;

    const currency = {
        style: 'currency',
        currency: 'USD',
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const prepareCheckoutInfo = () => {

        let checkout_info = {};
        checkout_info.logged_in_salon_id = auth().salon_id;
        checkout_info.selected_salon_id = state.salon_id;
        checkout_info.booking_for = state.bookingfor;
        checkout_info.room_type = "double";
        checkout_info.selected_date = state.date;
        checkout_info.opt_for_email = state.send_email == 1;
        checkout_info.opt_for_sms = state.send_sms == 1;
        checkout_info.payment_info = state.payment_info;


        const members = () => {
            if (state.bookingfor == 'myself') {
                return {
                    "personal_info": {
                        "first_name": auth().first_name,
                        "last_name": auth().last_name,
                        "email": auth().email,
                        "phone": auth().phone,
                        "promo_code": "",
                        "is_logged_in_user": true
                    },
                    selected_slot: JSON.parse(state.timeslot),
                    forms: {},
                }
            }

            if (state.bookingfor == 'someone') {
                return {
                    "personal_info": {
                        "first_name": state.guest.first_name,
                        "last_name": state.guest.last_name,
                        "email": state.guest.email,
                        "phone": state.guest.phone,
                        "promo_code": state.guest.promo_code,
                        is_logged_in_user: false
                    },
                    selected_slot: JSON.parse(state.guest.timeslot),
                    forms: {},
                }
            }
        }

        var memebrs = [];
        memebrs.push(members());
        checkout_info.members = memebrs;
        // "payment_info": {
        //     "card_exist" : true,
        //     "name_on_card": "Test User",
        //     "card_number": "4111111111111111",
        //     "exp_date": "11/2033",
        //     "cvv": "123"
        // }


        setCheckoutinfo(checkout_info);
    }

    useEffect(() => {
        console.log(methods.error)
    }, [methods.error])

    const submit = (data) => {

        // console.log(data.payment_info.card_number.length);

        if (data.payment_info.card_number.length < 14) {
            methods.setError('payment_info.card_number', {
                type: "server",
                message: 'card number should be minimum 14 digits',
            });
            setCheckoutSubmitted(false);
            return;
        }


        var sxpdate = data.payment_info.exp_date.match(/\W/);

        if (!sxpdate) {
            methods.setError('payment_info.exp_date', {
                type: "server",
                message: 'expiry date is invalid',
            });
            setCheckoutSubmitted(false);
            return;
        }

        if (sxpdate) {
            if (sxpdate.index != 2) {
                methods.setError('payment_info.exp_date', {
                    type: "server",
                    message: 'expiry date is invalid',
                });
                setCheckoutSubmitted(false);
                return;
            } else {
                var year = data.payment_info.exp_date.split('/')[1]
                var month = data.payment_info.exp_date.split('/')[0]

                if (month > 12) {
                    methods.setError('payment_info.exp_date', {
                        type: "server",
                        message: 'invalid exp. month',
                    });
                    setCheckoutSubmitted(false);
                    return;
                }

                if (year < new Date().getFullYear()) {
                    methods.setError('payment_info.exp_date', {
                        type: "server",
                        message: 'invalid exp. year',
                    });
                    setCheckoutSubmitted(false);
                    return;
                }
            }
        }

        if (!data.payment_info.cvv) {
            methods.setError('payment_info.cvv', {
                type: "server",
                message: 'cvv required',
            });
            setCheckoutSubmitted(false);
            return
        }


        if (data.payment_info.phone_number.length < 10) {
            methods.setError('payment_info.phone_number', {
                type: "server",
                message: 'min 10 digits required',
            });
            setCheckoutSubmitted(false);
            return
        }

        // console.log(data);

        data['step'] = 6;
        actions.updateAction(data);
        // console.log(state);

        setCheckoutSubmitted(true);
        // console.log(checkoutSubmitted)

    }

    useEffect(() => {
        // console.log(checkoutSubmitted)
        if (checkoutSubmitted) {
            // checkoutinfo.
            checkoutinfo.payment_info = {
                "card_exist": state.payment_info.card_exist,
                "name_on_card": state.payment_info.name_on_card,
                "card_number": state.payment_info.card_number,
                "exp_date": state.payment_info.exp_date,
                "cvv": state.payment_info.cvv
            };

            // return;
            axios.post('client/book-an-appointment', checkoutinfo)
                .then((res) => {
                    if (res.data.status) {
                        // toast.success(res.data.message, {
                        //     position: toast.POSITION.BOTTOM_CENTER
                        // })
                        // console.log(actions);

                        actions.clearAction(state);

                        if (sessionStorage.getItem("__LSM__")) {
                            sessionStorage.clear();
                        };

                        setshowConfirmedModal(true);

                    } else {
                        toast.error(res.data.message, {
                            position: toast.POSITION.BOTTOM_CENTER
                        })

                    }

                    // actions();
                })
                .catch((res) => {
                    // console.log(res);
                    setCheckoutSubmitted(false)

                    if (res.response.status == 400) {

                        // // console.log(res.response.data)
                        if (Array.isArray(res.response.data.data)
                            && res.response.data.data.length != 0
                        ) {
                            res.response.data.data.map((err) => {
                                toast.error(err.message, {
                                    position: toast.POSITION.BOTTOM_CENTER
                                })
                            })
                        } else {
                            toast.error(res.response.data.message, {
                                position: toast.POSITION.BOTTOM_CENTER
                            })
                        }

                    }

                    // console.log(res.response.status);
                    if (res.response.status == 422) {
                        const serrors = res.response.data.errors;
                        toast.error(res.response.data.message ?? 'something went wrong please try again!', {
                            position: toast.POSITION.BOTTOM_CENTER
                        })


                        if (serrors?.CardHolder) {
                            methods.methods.setError('CardHolder', {
                                type: "server",
                                message: serrors.payment_info.name_on_card[0],
                            });
                        }

                        if (serrors?.CardNumber) {
                            methods.methods.setError('CardNumber', {
                                type: "server",
                                message: serrors.payment_info.card_number[0],
                            });
                        }

                        if (serrors?.ExpMonth) {
                            methods.methods.setError('ExpMonth', {
                                type: "server",
                                message: serrors.payment_info.exp_date[0],
                            });
                        }

                        // if (serrors?.ExpYear) {
                        //     methods.methods.setError('ExpYear', {
                        //         type: "server",
                        //         message: serrors.ExpYear[0],
                        //     });
                        // }

                        // 
                    }

                })
        }

    }, [checkoutSubmitted]);

    useEffect(() => {
        setTotal((subtotal + estimatedtax) - discount);
    }, [subtotal, discount])

    useEffect(() => {
        // console.log(total)
    }, [total])



    useEffect(() => {
        // setSession(state.service.map((value, i) => {
        //     // setSubtotal(value.price + subtotal);
        //     return JSON.parse(value);
        // }))

        if (!state.location) {
            navigate(process.env.PUBLIC_URL + '/dashboard')
            return;
        }

        if (state.bookingfor == 'group') {
            setSession(state.group.map((client, i) => {
                // // console.log(client.services)
                if (typeof client.services == 'string') {
                    // return JSON.parse(client.services).map((session, i) => {
                    return JSON.parse(client.services);
                    // })[0]
                } else {
                    return (client.services.map((session2, i) => {
                        return JSON.parse(session2);
                    }))[0]
                }
            }))
        }

        if (state.bookingfor == 'someone') {
            var sessiontypeids = [];

            sessiontypeids.push(
                state.guest.services.map((session2, i) => {
                    return JSON.parse(session2);
                }))

            setSession(sessiontypeids[0]);

            if (state.guest.timeslot) {
                setTimeslots(JSON.parse(state.guest.timeslot));
            }
        }

        if (state.bookingfor == 'myself') {
            setSession(state.service.map((value, i) => {
                return JSON.parse(value);
            }))

            if (state.timeslot) {
                setTimeslots(JSON.parse(state.timeslot));
            }
        }


        setLocation(JSON.parse(state.location));

        // // console.log()
        methods.setValue('payment_info.phone_number', auth().phone);
        methods.setValue('payment_info.card_exist', 0);
        // setshowConfirmedModal(true);

        prepareCheckoutInfo();

    }, []);

    const back = () => {
        if (state.form_url) {
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/forms?title=' + state.form_url);
        } else {
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/forms');

        }
    };

    useEffect(() => {


        if (session) {

            var PRICES = (session.map((sess, i) => {

                return sess.price;
            }))
            var sum = PRICES.reduce(function (a, b) {

                return parseFloat(a) + parseFloat(b);
            }, 0);
            setSubtotal(sum);
        }
        ;

    }, [session])

    useEffect(() => {
        // console.log(methods.formState.errors);
    }, [methods.formState.errors])

    useEffect(() => {
        methods.reset(cc);
    }, [cc]);

    useEffect(() => {
        getCC();
    }, [location]);

    useEffect(() => {

        if (checkoutinfo) {
            getCheckoutInfo();
        }

    }, [checkoutinfo])

    const [cehckoutdetails, setCHeckoutdetails] = useState(false);

    const getCheckoutInfo = () => {
        axios.post('client/get-checkout-info', checkoutinfo)
            .then(res => {
                if (res.data.data) {
                    setCHeckoutdetails(res.data.data);
                    setShowModal(true)

                } else {
                }
            })
    }

    const getCC = () => {

        if (location.salon_id && auth().client_id) {
            axios.get('client/getClientCC', {
                params: {
                    "salon_id": location.salon_id,
                    "client_id": auth().client_id
                }
            })
                .then(res => {
                   
                    if (res.data.status) {
                        // console.log(res.data.data)
                        setCc(res.data.data);
                        methods.setValue('payment_info.card_exist', 1);
                    } else {
                        methods.setValue('payment_info.card_exist', 0);
                    }
                })
        }

    }

    const [showModal, setShowModal] = useState(false);
    const [showConfirmedModal, setshowConfirmedModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const watchcreditcard = methods.watch('payment_info.card_number');

    const watchcvv = methods.watch('payment_info.cvv');

    const watchexpirydate = methods.watch('payment_info.exp_date');

    const watchphonenumber = methods.watch('payment_info.phone_number');

    useEffect(() => {

        if (watchphonenumber) {

            methods.setValue('payment_info.phone_number', watchphonenumber.replace(/[^0-9]/ig, '').substr(0, 10))
        }
    }, [watchphonenumber])

    useEffect(() => {
        if (watchcreditcard) {
            methods.setValue('payment_info.card_number', watchcreditcard.replace(/[^0-9*]/ig, '').substr(0, 16));
        }

    }, [watchcreditcard]);

    useEffect(() => {
        if (watchcvv) {
            methods.setValue('payment_info.cvv', watchcvv.replace(/[^0-9*]/ig, '').substr(0, 4));
        }

    }, [watchcvv])

    useEffect(() => {

        if (watchexpirydate) {
            const expDateFormatter = watchexpirydate.replace(/\//g, "").substring(0, 2) +
                (watchexpirydate.length > 2 ? '/' : '') +
                watchexpirydate.replace(/\//g, "").substring(2, 6);

            methods.setValue('payment_info.exp_date', expDateFormatter);
        }



    }, [watchexpirydate])

    useEffect(() => {
        // console.log(methods.formState.errors)
    }, [methods.formState.errors])

    return (
        <>

            <EmptyLocation />

            <EmptyService />

            <EmptyDateTime />

            <EmptyConfirm />

            <EmptyForms />

            <li className="active">
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/checkout-icon.svg`} width="13.65" height="15.92"
                            alt="checkout icon" />
                    </div>

                    {showConfirmedModal ? <Modal setShowModal={setshowConfirmedModal} content={<Confirmed

                    />} /> : null}

                    <h2 className="steps-box-title">checkout</h2>
                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(submit)}>

                            <input type={'hidden'} {...methods.register(`payment_info.card_exist`)} />

                            {showModal ? <Modal setShowModal={setShowModal} content={<Promotional
                                methods={methods}
                                closeModal={closeModal}
                                cehckoutdetails={cehckoutdetails}
                            />} /> : null}

                            <div className="bk-ap-steps-box-cont">
                                <div className="card p-2 px-3 card-blue mb-3">
                                    {/* <div className="card-blue-left">
                                        <img src={`${process.env.PUBLIC_URL}/images/loyalty.svg`} alt="loyalty" width="14" height="14" />
                                        want to save [$00.00] on your skincare workout?
                                        {cehckoutdetails ? cehckoutdetails.offer_text : ''}
                                    </div>
                                    <div className="card-blue-right">
                                        <a href="https://www.skoah.com/pages/memberships">become a member today!</a>
                                    </div> */}
                                </div>
                                <div className="card-form">
                                    <form className="row gx-3">
                                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                                            <div className="card p-3 h-100">
                                                <h3 className="form-title mb-2">credit card hold</h3>
                                                <p className="para mb-1">we require a credit card to hold all appointments, nothing will be charged until after your appointment</p>
                                                <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.credit_card_error && methods.formState.errors?.credit_card_error.message}</div>

                                                <div className="row gx-2">
                                                    <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                        <label htmlFor="nameoncard" className="form-label">name on card</label>
                                                        <input type="text" {...methods.register(`payment_info.name_on_card`)} className="form-control form-control-gray" placeholder="name on card" id="nameoncard" />
                                                        <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.payment_info?.name_on_card && methods.formState.errors?.payment_info?.name_on_card.message}</div>
                                                    </div>

                                                    <div className={'col-12 col-sm-12 col-md-12  form-group mb-1 ' + (methods.formState.errors?.payment_info?.card_number ? 'invalid-error' : '')}>
                                                        <label htmlFor="cardnumber" className="form-label">card number</label>
                                                        <input type="text" {...methods.register(`payment_info.card_number`, {
                                                            required: "required"
                                                        })}
                                                            className="form-control form-control-gray" placeholder="card number" id="cardnumber" />
                                                        <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.payment_info?.card_number && methods.formState.errors?.payment_info?.card_number.message}</div>
                                                    </div>

                                                    <div className={'col-12 col-sm-12 col-md-6 form-group mb-1' + (methods.formState.errors?.payment_info?.exp_date ? ' invalid-error' : '')}>
                                                        <label htmlFor="expdate" className="form-label">exp. date</label>
                                                        <input type="text" formate={'mm/yyyy'} maxLength={7} {...methods.register(`payment_info.exp_date`)} className="form-control form-control-gray" placeholder="mm/yyyy" id="expdate" />
                                                        {/* <input type="number" max={4} {...methods.register(`ExpYear`)} className="form-control form-control-gray" placeholder="yyyy" id="" /> */}
                                                        <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.payment_info?.exp_date && methods.formState.errors?.payment_info?.exp_date.message}</div>
                                                        {/* <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.ExpYear && methods.formState.errors?.ExpYear.message}</div> */}
                                                    </div>

                                                    <div className={'col-12 col-sm-12 col-md-6 form-group mb-1' + (methods.formState.errors?.payment_info?.cvv ? 'i nvalid-error' : '')}>
                                                        <label htmlFor="cvv" className="form-label">cvv</label>
                                                        <input type="text"  {...methods.register(`payment_info.cvv`, {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 4,
                                                                message: "max length is 16"
                                                            }, minLength: {
                                                                value: 3,
                                                                message: 'min length is 3'
                                                            }
                                                        })}
                                                            className="form-control form-control-gray" placeholder="cvv" id="cvv" />
                                                        <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.payment_info?.cvv && methods.formState.errors?.payment_info?.cvv.message}</div>
                                                    </div>

                                                    <div className={'col-12 col-sm-12 col-md-6 form-group mb-1' + (methods.formState.errors?.payment_info?.phone_number ? ' invalid-error' : '')}>
                                                        <label htmlFor="phonenumber" className="form-label">phone number</label>
                                                        <input type="number" {...methods.register(`payment_info.phone_number`, { required: true })} className="form-control form-control-gray" placeholder="phone number" id="phonenumber" />
                                                        <div className="invalid-feedback fw-normal position-static">{methods.formState.errors?.payment_info?.phone_number && methods.formState.errors?.payment_info?.phone_number.message}</div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                                            <div className="card p-3 h-100">
                                                <h3 className="form-title mb-2">appointment details</h3>
                                                {
                                                    state.bookingfor == 'myself' ?
                                                        <>
                                                            <div className="confirm-detail confirm-detail-before">
                                                                <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="10" height="11" alt="confirm icon" /></span>
                                                                {timeslots ?
                                                                    <div className="confirm-detail-title">


                                                                        {new Date(state.date + 'T' + timeslots.services[0].start_time).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}
                                                                        {' '}
                                                                        <span>at</span> {formatAMPM(new Date(state.date + 'T' + timeslots.services[0].start_time))}


                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            <div className="confirm-detail confirm-detail-before">
                                                                <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="10" height="11" alt="confirm icon" /></span>
                                                                <div className="confirm-detail-title">{location.location_name}</div>
                                                                <div className="confirm-detail-desc">
                                                                    {location.address1},<br />
                                                                    {location.city}, {location.postal_code}
                                                                </div>
                                                            </div>
                                                            {
                                                                session ?
                                                                    session.map((object, i) =>
                                                                        <div className="confirm-detail" key={i}>
                                                                            <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="10" height="11"
                                                                                alt="confirm icon" /></span>
                                                                            <div className="confirm-detail-title">{object.name} <span>${object.price}</span></div>
                                                                            <div className="confirm-detail-desc">
                                                                                {/* {object.description} */}
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                    : ''
                                                            }
                                                        </> : ''
                                                }
                                                {
                                                    state.bookingfor == 'someone' ?
                                                        <div className="card card-confirm p-2">
                                                            <div className="confirm-detail">
                                                                <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="10" height="11"
                                                                    alt="confirm icon" /></span>
                                                                {timeslots ?
                                                                    <div className="confirm-detail-title">


                                                                        {new Date(state.date + 'T' + timeslots.services[0].start_time).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}
                                                                        {' '}
                                                                        <span>at</span> {formatAMPM(new Date(state.date + 'T' + timeslots.services[0].start_time))}


                                                                    </div>
                                                                    : ''
                                                                }



                                                            </div>
                                                            <div className="confirm-detail">
                                                                <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="10" height="11"
                                                                    alt="confirm icon" /></span>
                                                                <div className="confirm-detail-title">{location.location_name}</div>
                                                                <div className="confirm-detail-desc">
                                                                    {location.address1},<br />
                                                                    {location.city}, {location.postal_code}
                                                                </div>
                                                            </div>
                                                            {
                                                                state.guest.services ?
                                                                    state.guest.services.map((object, i) =>
                                                                        <div className="confirm-detail" key={i}>
                                                                            <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="10" height="11"
                                                                                alt="confirm icon" /></span>
                                                                            <div className="confirm-detail-title">{JSON.parse(object).name} <span>${JSON.parse(object).price}</span></div>
                                                                            <div className="confirm-detail-desc">
                                                                                {/* {JSON.parse(object).description} */}
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                    : ''
                                                            }

                                                        </div>
                                                        : ''
                                                }
                                                <hr className="hr-line" />
                                                <h3 className="form-title mb-2">checkout summary</h3>
                                                <div className="check-summary">
                                                    <div className="check-summary-in">
                                                        <div className="check-summary-lft">subtotal</div>
                                                        <div className="check-summary-rgt">
                                                            {/* {parseFloat(subtotal).toLocaleString('en-US', currency)} */}
                                                            {cehckoutdetails ? cehckoutdetails.sub_total : ''}
                                                        </div>
                                                    </div>
                                                    <div className="check-summary-in">
                                                        <div className="check-summary-lft">estimated tax</div>
                                                        <div className="check-summary-rgt">
                                                            {/* {parseFloat(estimatedtax).toLocaleString('en-US', currency)} */}
                                                            {cehckoutdetails ? cehckoutdetails.estimated_tax : ''}
                                                        </div>
                                                    </div>
                                                    <div className="check-summary-in">
                                                        <div className="check-summary-lft">discounts</div>
                                                        <div className="check-summary-rgt">
                                                            {/* {parseFloat(discount).toLocaleString('en-US', currency)} */}
                                                            {cehckoutdetails ? cehckoutdetails.discounts : ''}
                                                        </div>
                                                    </div>
                                                    <div className="check-summary-in total-sum">
                                                        <div className="check-summary-lft">total</div>
                                                        <div className="check-summary-rgt">

                                                            {/* {parseFloat(total).toLocaleString('en-US', currency)} */}
                                                            {cehckoutdetails ? cehckoutdetails.total : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-6 mb-3'></div>
                                        <div className='col-12 col-sm-12 col-md-6 mb-3'>
                                            <p class="promo-msg">promo codes will apply at checkout on day of appointment</p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="step-btns">
                                <button type="button" className="btn btn-light ms-2" onClick={(e) => back()}  >back</button>

                                <button type="submit" className="btn btn-primary ms-2" >submit</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </li>
        </>
    );
}

export default Checkout;

export function EmptyCheckout() {

    return (
        <>
            <li >
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/checkout-icon.svg`} width="13.65" height="15.92"
                            alt="checkout icon" />
                    </div>
                    <h2 className="steps-box-title">checkout</h2>
                </div>
            </li>
        </>
    )
}

function Promotional(props) {

    const { actions, state } = useStateMachine({ updateAction });


    const submitLocally = () => {
        var data = props.methods.getValues()
        actions.updateAction(data);
        props.closeModal()
    }

    useEffect(() => {
        props.methods.reset(state);
    }, [])

    useEffect(() => {

        props.methods.reset({
            send_email: props.cehckoutdetails.is_opt_in_for_email_marketing,
            send_sms: props.cehckoutdetails.is_opt_in_for_sms_marketing,
        });
    }, [props.cehckoutdetails])

    return (
        <>

            <ConnectForm>
                {

                    ({ register, watch }) =>
                        <div className="modal" id="onload" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '291px' }}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2 className="modal-title" id="exampleModalLabel">want exclusive promotions and discounts? sign up for our email and sms marketing!</h2>
                                        <button type="button" onClick={e => props.closeModal()} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <img src={`${process.env.PUBLIC_URL}/images/close_modal.svg`} width="11" height="11" alt="close icon" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="card-form " >
                                            <div className="max-250 w-100 m-auto">
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" {...register('send_email')} type="checkbox" value="1" id="email-market" />
                                                    <label class="form-check-label" for="mail-market">
                                                        email marketing
                                                    </label>
                                                </div>
                                                {/* <div class="form-check mb-3">
                                                    <input class="form-check-input" {...register('send_sms')} type="checkbox" value="1" id="sms-market" />
                                                    <label class="form-check-label" for="sms-market">
                                                        sms marketing
                                                    </label>
                                                </div> */}

                                                {/* <div className="form-group mb-1">
                                                    <label for="email" className="form-label">email</label>
                                                    <input type="checkbox" className="form-control form-control-gray" {...register('marketing.email')} placeholder="email" id="email" required />
                                                </div>
                                                <div className="form-group mb-1">
                                                    <label for="phonenumber" className="form-label">phone number</label>
                                                    <input type="checkbox" className="form-control form-control-gray" {...register('marketing.phone')} placeholder="phone number" id="phonenumber" required />
                                                </div> */}
                                            </div>
                                            <div className="form-group mt-3 mb-0">
                                                <button className="btn btn-primary text-center w-100" type='buttom' onClick={() => submitLocally()}>submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </ConnectForm>
        </>
    )
}

function Confirmed(props) {
    return (
        <>
            <div class="modal" id="onload" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '291px' }}>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 class="modal-title" id="exampleModalLabel">see you soon!</h2>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={`${process.env.PUBLIC_URL}/images/close_modal.svg`} width="11" height="11" alt="close icon" />
                            </button>
                        </div>
                        <div class="modal-body">

                            <div class="max-226 w-100 m-auto">
                                <p class="para text-center">Your appointment has been confirmed,<br /> we’re looking forward to
                                    seeing you!</p>
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <Link to={`${process.env.PUBLIC_URL}/dashboard`} class="btn btn-primary text-center w-100">okay</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}