import React, { Component, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsAuthenticated, useSignIn, withSignIn } from 'react-auth-kit'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { toast } from 'react-toastify';
import axiosinstance from '../config/axiosinstance';
import Cookies from 'js-cookie';

function ResetPassword() {

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated()

    // console.log(isAuthenticated());



    const signIn = useSignIn()
    const { register, handleSubmit, setError, setValue, watch, formState: { errors } } = useForm();


    useEffect(() => {
        if (isAuthenticated()) {
            navigate(process.env.PUBLIC_URL+'/dashboard');
        }
    });


    async function onSubmit(formData) {

        // e.preventDefault()

        axios.post(`client/update-reset-password`, formData)
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    if (res.data.status) {
                        toast.success(res.data.message,
                            { position: toast.POSITION.BOTTOM_CENTER }
                        )
                    } else {
                        if (res.data.message) {
                            // // console.log(serrors.password);
                            setError('password', {
                                type: "server",
                                message: res.data.message,
                            });
                        }
                        // toast.error(res.data.message,
                        //     { position: toast.POSITION.BOTTOM_CENTER }
                        // )
                    }
                }



                // // console.log(res.data.data.status);


            })
            .catch((error) => {
                // // console.log(error);
                // if (error.response.status == 422) {
                //     const serrors = error.response.data.errors;
                //     // console.log(serrors);
                //     if (serrors.email) {
                //         setError('email', {
                //             type: "server",
                //             message: serrors.email[0],
                //         });
                //     }
                //     if (serrors.password) {
                //         // // console.log(serrors.password);
                //         setError('password', {
                //             type: "server",
                //             message: serrors.password[0],
                //         });
                //     }
                // }
                // // console.log(errors);
                // toast.error(error.response.data.message, {
                //     position: toast.POSITION.BOTTOM_CENTER
                // });


            });
    }

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if (searchParams.get("q")) {
            setValue('salon_id', Cookies.get('saloon'));
            setValue('code', searchParams.get("q"));
        } else {
            navigate(process.env.PUBLIC_URL+'/dashboard');
        }

    }, [])


    return (
        <>
            <div className="login-bg">
                <div className="signin-cont">
                    <div className="signin-cont-in">
                        <div className="signin-cont-in-lft">
                            <div className="signin-cont-in-lft-in">
                                <img src="images/logo.svg" width="183.12" height="86" alt="skoah Logo" />
                                <div className="powered-by">
                                    <img src="images/powered-by.svg" width="129.37" height="29.66" alt="powered by saloncloudsplus img" />
                                </div>
                            </div>
                        </div>
                        <div className="signin-cont-in-rgt">
                            <div className="signin-cont-in-rgt-in">
                                <h1>welcome</h1>
                                <h2>Reset Password</h2>
                                <form className="m-0 needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                                    <input type={'hidden'} {...register("salon_id")} />
                                    <input type={'hidden'} {...register("code")} />
                                    <div className={'form-group mb-1 ' + (errors.password ? 'invalid-error' : '')}>
                                        <label htmlFor="password" className="form-label">New password</label>
                                        <input type="password" {...register("password", { required: true })} className="form-control" placeholder="password" id="password" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.password && errors.password.message}</div>
                                    </div>
                                    <div className={'form-group mb-1 ' + (errors.password_confirmation ? 'invalid-error' : '')}>
                                        <label htmlFor="password" className="form-label">Confirm password</label>
                                        <input type="password" {...register("password_confirmation", { required: true })} className="form-control" placeholder="password" id="password" required />
                                        {/* <div class="invalid-feedback fw-normal position-static">{errors.password_confirmation && errors.password_confirmation.message}</div> */}
                                    </div>

                                    <div className="form-group my-2">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        {/* <a href="dashboard.html" className="btn btn-primary" aria-label="click to login">log in</a> */}
                                        {/* <Link tfo="/login" aria-label="click to log-in" role="link"> log in</Link> */}
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-footer">
                    <div className="signin-footer-lft">© skoah 2023 | designed and developed by webappclouds</div>
                    <div className="signin-footer-rgt"><a href="">terms & conditions</a> <a href="">privacy policy</a></div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;