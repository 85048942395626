// First we need to import axios.js
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


// const navigate = useNavigate();

// Next we make an 'instance' of it
axios.defaults.baseURL = process.env.REACT_APP_API_HOST_URL + `api/web/`;

if (process.env.REACT_APP_AUTH_TYPE == 'cookie') {
    if (Cookies.get(process.env.REACT_APP_AUTH_NAME)) {
        axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + Cookies.get(process.env.REACT_APP_AUTH_NAME);
        // // console.log(axiosinstance.defaults.headers.common);
    }
}
const axiosinstance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_API_HOST_URL + `api/`
});

// Where you would set stuff like your 'Authorization' header, etc ...
// axiosinstance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// // console.log(process.env.REACT_APP_AUTH_TYPE);
// // console.log(process.env.REACT_APP_AUTH_NAME);

if (process.env.REACT_APP_AUTH_TYPE == 'cookie') {
    if (Cookies.get(process.env.REACT_APP_AUTH_NAME)) {
        axiosinstance.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + Cookies.get(process.env.REACT_APP_AUTH_NAME);
        // // console.log(axiosinstance.defaults.headers.common);
    }
}




// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent

    if (process.env.REACT_APP_AUTH_TYPE == 'cookie') {
        if (Cookies.get(process.env.REACT_APP_AUTH_NAME)) {
            axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + Cookies.get(process.env.REACT_APP_AUTH_NAME);
            // // console.log(axiosinstance.defaults.headers.common);
        }
    }

    document.body.classList.add('loading-indicator');

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    
    if (process.env.REACT_APP_AUTH_TYPE == 'cookie') {
        if (Cookies.get(process.env.REACT_APP_AUTH_NAME)) {
            axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + Cookies.get(process.env.REACT_APP_AUTH_NAME);
            // // console.log(axiosinstance.defaults.headers.common);
        }
    }

    document.body.classList.remove('loading-indicator');

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    document.body.classList.remove('loading-indicator');

    if (error.response.status === 401) {
        //place your reentry code
        // // console.log(Cookies.remove('_auth_state'))
        Cookies.remove('_auth_state')
        Cookies.remove('_auth_storage')
        Cookies.remove('_auth_type')
        Cookies.remove('_auth')
        window.location.reload();
        // navigate(process.env.PUBLIC_URL+'/login');
        window.location.href = process.env.PUBLIC_URL+'/login';

    }

    return Promise.reject(error);
});

export default axiosinstance;