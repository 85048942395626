import React, { Component, useEffect } from 'react';
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { Link, Outlet, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Account from './account/Account';
import BookAppointment from './book-appointment/BookAppointment';


function Dashboard() {

    const isAuthenticated = useIsAuthenticated()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    const location = useLocation();

    const isReschedule = searchParams.get('reschedule');
    // // console.log(location.pathname.split("/")[location.pathname.split("/").length-1]);
    const currentLocation = location.pathname.split("/")[location.pathname.split("/").length-1];
    const currentstep = location.pathname.split("/")[3];

    useEffect(() => {
        // console.log(isAuthenticated());
        if (!isAuthenticated()) {
            if (sessionStorage.getItem("__LSM__")) {
                sessionStorage.clear();
            };

            

            navigate(process.env.PUBLIC_URL + '/login');
            return;
        }
        else {

        }

    });

    const signOut = useSignOut();

    const auth = useAuthUser()

    const logOut = () => {
        sessionStorage.clear();
        signOut();
    }

    return (
        <>

            <div className="dashbaord-admin">
                <div className="dashbaord-admin-nav-mob">
                    <div className="mob-logo">
                        <img src={`${process.env.PUBLIC_URL}/images/logo-mob.svg`} width="146" height="38" alt="Skoah Logo Img" />
                    </div>
                    <div className="mob-ham">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="15.5" viewBox="0 0 17.5 15.5">
                            <path id="menu-1" d="M7.283,19H20m0-7H4M20,5H12.972" transform="translate(-3.25 -4.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5"></path>
                        </svg>
                    </div>
                </div>
                <div className="dashbaord-admin-nav">
                    <div className="dashbaord-admin-logo">
                        <img src={`${process.env.PUBLIC_URL}/images/logo-white.svg`} width="157.97" height="74.19" alt="Skoah Logo Img" />
                        <h1 className="dashbaord-admin-logo-anchor m-0" data-bs-toggle="tooltip" data-bs-placement="bottom"
                        >
                            <>
                                {
                                    auth().image ?
                                        <img src={auth().image} width="30" height="30" alt="Skoah User img" />

                                        :

                                        <img src={`${process.env.PUBLIC_URL}/images/placeholder.svg`} width="30" height="30" alt={auth().first_name + ' ' + auth().last_name} />

                                }
                                <span>welcome,<br /><small>{auth().first_name}{' '} {auth().last_name}</small></span>
                            </>
                        </h1>
                    </div>
                    <div className="dashbaord-admin-menu">
                        <ul>
                            <li className={currentLocation == 'book-appointment' ? 'active' : ''}> <Link to={`${process.env.PUBLIC_URL}/dashboard/book-appointment`}>book an appointment</Link></li>
                            <li className={currentLocation == 'account' ? 'active' : ''}> <Link to={`${process.env.PUBLIC_URL}/dashboard/account`}>my account</Link></li>
                            <li className={currentLocation == 'gift-card' ? 'active' : ''}> <Link to={`${process.env.PUBLIC_URL}/dashboard/gift-card`}>check giftkard balance</Link></li>
                            <li > <Link onClick={(e) => logOut()}>logout</Link></li>
                        </ul>
                    </div>
                    <div className="dashbaord-admin-termsbar">
                        <img src={`${process.env.PUBLIC_URL}/images/powered-by-salonclouds.svg`} width="129.37" height="26.76"
                            alt="Powered By Saloncloudsplus" />
                        <p>© skoah 2023 | designed and<br /> developed by webappclouds</p>
                    </div>

                </div>
                <div className="dashbaord-admin-content">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default Dashboard;