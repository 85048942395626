import React, { Component, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useSignIn, withSignIn } from 'react-auth-kit'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { toast } from 'react-toastify';
import axiosinstance from '../config/axiosinstance';
import Cookies from 'js-cookie';

function ForgetPassword() {

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated()

    // console.log(isAuthenticated());



    const signIn = useSignIn()
    const { register, handleSubmit, setError, setValue, watch, formState: { errors } } = useForm();


    useEffect(() => {
        if (isAuthenticated()) {
            navigate(process.env.PUBLIC_URL+'/dashboard');
        }
    });

    useEffect(() => {
        setValue('salon_id', Cookies.get('saloon'));
    }, [])


    async function onSubmit(formData) {


        await axios.post(`client/forgot-password`, formData)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        toast.success(res.data.message,
                            { position: toast.POSITION.BOTTOM_CENTER }
                        )
                    } else {
                        toast.error(res.data.message,
                            { position: toast.POSITION.BOTTOM_CENTER }
                        )
                    }

                }
            })
            .catch((error) => {
                // // console.log(error);
                if (error.response.status == 422) {
                    const serrors = error.response.data.errors;
                    // // console.log(serrors);
                    if (serrors.email) {
                        setError('email', {
                            type: "server",
                            message: serrors.email[0],
                        });
                    }

                }

                if (error.response.status == 401) {
                    setError('email', {
                        type: "server",
                        message: error.response.data.message,
                    });
                }
            });
    }


    return (
        <>
            <div className="login-bg">
                <div className="signin-cont">
                    <div className="signin-cont-in">
                        <div className="signin-cont-in-lft">
                            <div className="signin-cont-in-lft-in">
                                <img src="images/logo.svg" width="183.12" height="86" alt="skoah Logo" />
                                <div className="powered-by">
                                    <img src="images/powered-by.svg" width="129.37" height="29.66" alt="powered by saloncloudsplus img" />
                                </div>
                            </div>
                        </div>
                        <div className="signin-cont-in-rgt">
                            <div className="signin-cont-in-rgt-in">
                                <h1>welcome</h1>
                                <h2>Forget Password</h2>
                                <form className="m-0 needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                                    <div className={'form-group mb-1 ' + (errors.email ? 'invalid-error' : '')}>
                                        <label htmlFor="email" className="form-label">email</label>
                                        <input type={'hidden'} {...register("salon_id")} />
                                        <input type="email" {...register("email", { required: true })} className="form-control" placeholder="email" id="email" required />

                                        <div class="invalid-feedback fw-normal position-static">{errors.email && errors.email.message}</div>
                                    </div>

                                    <div className="form-group my-2">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        {/* <a href="dashboard.html" className="btn btn-primary" aria-label="click to login">log in</a> */}
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-footer">
                    <div className="signin-footer-lft">© skoah 2023 | designed and developed by webappclouds</div>
                    <div className="signin-footer-rgt"><a href="">terms & conditions</a> <a href="">privacy policy</a></div>
                </div>
            </div>
        </>
    );
}

export default ForgetPassword;