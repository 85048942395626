import { useStateMachine } from 'little-state-machine';
import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EmptyCheckout } from './Checkout';
import clearAction from '../../lib/clearAction';

import { EmptyDateTime } from './DateTime';
import { EmptyForms } from './Forms';
import { EmptyLocation } from './Location';
import { EmptyService } from './Service';
import updateAction from '../../lib/updateAction';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';

function Confirm() {


    const [session, setSession] = useState(false);
    const [location, setLocation] = useState(false);
    const navigate = useNavigate();
    // const { actions, state } = useStateMachine({ updateAction });
    const { actions, state } = useStateMachine({ clearAction, updateAction });

    const [timeslots, setTimeslots] = useState(false);

    const back = () => {
        state.timeslot = null;
        actions.updateAction(state);

        // reschedule
        if (state.isReschedule) {
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time?reschedule=' + state.appt.appointment_group_id);
            return;
        }
        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time');
    };

    const next = () => {

        if (state.isReschedule) {

            const postdata = {};
            postdata.salon_id = state.appt.selected_salon_id;
            postdata.status_type = "reschedule";
            postdata.appointment_info = {};

            //  members = {};

            const members = state.appt.members.map((member) => {

                return {
                    personal_info: member.personal_info,
                    selected_services: member.selected_services.map((services) => {

                        for (let index = 0; index < timeslots.services.length; index++) {
                            const element = timeslots.services[index];
                            if (element.service_id == services.service_id) {
                                return {
                                    "appointment_id": services.appointment_id,
                                    "start_time": element.start_time,
                                    "end_time": element.end_time,
                                    "service_id": services.service_id,
                                    "staff_id": element.staff_id,
                                    "resource_id": element.resource_id,
                                    "service_name": services.service_name
                                }
                            }


                        }

                    })
                }
            })

            postdata.appointment_info.members = members;
            postdata.appointment_info.appointment_group_id = state.appt.appointment_group_id;
            postdata.appointment_info.selected_salon_id = state.appt.selected_salon_id;
            postdata.appointment_info.booking_for = state.appt.booking_for;
            postdata.appointment_info.room_type = state.appt.room_type;
            postdata.appointment_info.location_name = state.appt.location_name;
            postdata.appointment_info.date = state.date;

            console.log(postdata);

            axios.post('client/update-appointment-status', postdata)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status) {
                        // toast.success(res.data.message, {
                        //     position: toast.POSITION.BOTTOM_CENTER,
                        //     toastId: 'appointmentcanceled'
                        // })
                        actions.clearAction();
                        setshowConfirmedModal(true);

                    }

                })
                .catch((res) => {
                    // // console.log(res);

                    if (res.response.status == 400) {

                    }


                })

            return;
        }
        actions.updateAction({ step: 5, confirmed: true });

        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/forms');
    };

    useEffect(() => {
        console.log(timeslots)
    }, [timeslots])

    useEffect(() => {

        if (state.isReschedule) {

            if (state.appt.booking_for == 'myself') {

                const services = state.appt.members.map((members) => {
                    return members.selected_services.map((service) => {
                        return { name: service.service_name, price: service.service_price, description: '' };
                    })
                });

                setSession(services[0])

                if (state.timeslot) {
                    setTimeslots(JSON.parse(state.timeslot));
                }
            }

        } else {

            if (!state.location) {
                navigate(process.env.PUBLIC_URL + '/dashboard')
                return;
            } else {
                if (Array.isArray(state.location)) {
                    setLocation(JSON.parse(state.location[0].toLowerCase()))
                } else {
                    setLocation(JSON.parse(state.location.toLowerCase()));

                }

            }

            if (state.bookingfor == 'group') {
                setSession(state.group.map((client, i) => {
                    // // console.log(client.services)
                    if (typeof client.services == 'string') {
                        // return JSON.parse(client.services).map((session, i) => {
                        return JSON.parse(client.services);
                        // })[0]
                    } else {
                        return (client.services.map((session2, i) => {
                            return JSON.parse(session2);
                        }))[0]
                    }
                }))
            }

            if (state.bookingfor == 'someone') {
                var sessiontypeids = [];

                sessiontypeids.push(state.guest.services.map((session2, i) => {
                    return JSON.parse(session2);
                }))

                setSession(sessiontypeids);

                if (state.guest.timeslot) {
                    setTimeslots(JSON.parse(state.guest.timeslot));
                }
            }

            if (state.bookingfor == 'myself') {
                setSession(state.service.map((value, i) => {
                    return JSON.parse(value);
                }))

                if (state.timeslot) {
                    setTimeslots(JSON.parse(state.timeslot));
                }
            }
        }


        // // console.log(new Date(state.date+'T'+timeslots.services[0].start_time));

    }, []);

    useEffect(() => {

    }, [timeslots])

    useEffect(() => {
        console.log(session);
    }, [session]);

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const [showModal, setShowModal] = useState(false);
    const [showConfirmedModal, setshowConfirmedModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <>

            {showConfirmedModal ? <Modal setShowModal={setshowConfirmedModal} content={<Confirmed

            />} /> : null}

            <EmptyLocation />

            <EmptyService />

            <EmptyDateTime />

            <li className="active">
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/confirm-icon.svg`} width="15.16" height="15.16"
                            alt="confirm icon" />
                    </div>
                    <h2 className="steps-box-title">confirm appointment details</h2>
                    <div className="bk-ap-steps-box-cont">


                        {
                            state.bookingfor == 'myself' ?
                                <div className="card card-confirm p-2">
                                    <div className="confirm-detail">
                                        <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="10" height="11"
                                            alt="confirm icon" /></span>
                                        <div className="confirm-detail-title">

                                            {timeslots ?
                                                <div className="confirm-detail-title">

                                                    {new Date(state.date + 'T' + timeslots.services[0].start_time).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}
                                                    {' '}
                                                    <span>at</span> {formatAMPM(new Date(state.date + 'T' + timeslots.services[0].start_time))}

                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="confirm-detail">
                                        <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="10" height="11"
                                            alt="confirm icon" /></span>
                                        {
                                            state.isReschedule ?
                                                <div className="confirm-detail-title">{state.appt.location_name}</div>
                                                :
                                                <>
                                                    <div className="confirm-detail-title">{location.location_name}</div>
                                                    <div className="confirm-detail-desc">
                                                        {location.address1},<br />
                                                        {location.city}, {location.postal_code}
                                                    </div>
                                                </>
                                        }

                                    </div>
                                    {
                                        session ?
                                            session.map((object, i) =>
                                                <div className="confirm-detail" key={i}>
                                                    <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="10" height="11"
                                                        alt="confirm icon" /></span>
                                                    <div className="confirm-detail-title">{object.name} <span>${object.price}</span></div>
                                                    <div className="confirm-detail-desc">
                                                        {object.description}
                                                    </div>
                                                </div>
                                            )

                                            : ''
                                    }

                                </div>
                                : ''
                        }

                        {
                            state.bookingfor == 'someone' ?
                                <div className="card card-confirm p-2">
                                    <div className="confirm-detail">
                                        <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="10" height="11"
                                            alt="confirm icon" /></span>

                                        {timeslots ?
                                            <div className="confirm-detail-title">


                                                {new Date(state.date + 'T' + timeslots.services[0].start_time).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}
                                                {' '}
                                                <span>at</span> {formatAMPM(new Date(state.date + 'T' + timeslots.services[0].start_time))}


                                            </div>
                                            : ''
                                        }

                                        {/* <div className="confirm-detail-title">

                                            thursday, january 21, 2023 
                                            {new Date(JSON.parse(state.guest.timeslot).starttime).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}
                                            {' '}
                                            <span>at</span> {new Date(JSON.parse(state.guest.timeslot).starttime).toLocaleTimeString("en-US")}</div> */}
                                    </div>
                                    <div className="confirm-detail">
                                        <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="10" height="11"
                                            alt="confirm icon" /></span>
                                        <div className="confirm-detail-title">{location.location_name}</div>
                                        <div className="confirm-detail-desc">
                                            {location.address1},<br />
                                            {location.city}, {location.postal_code}
                                        </div>
                                    </div>
                                    {
                                        state.guest.services ?
                                            state.guest.services.map((object, i) =>
                                                <div className="confirm-detail" key={i}>
                                                    <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="10" height="11"
                                                        alt="confirm icon" /></span>
                                                    <div className="confirm-detail-title">{JSON.parse(object).name} <span>${JSON.parse(object).price}</span></div>
                                                    <div className="confirm-detail-desc">
                                                        {JSON.parse(object).description}
                                                    </div>
                                                </div>
                                            )

                                            : ''
                                    }

                                </div>
                                : ''
                        }

                        {
                            state.bookingfor == 'group' ?
                                state.group.map((obj, c) =>
                                    <div className="card card-confirm p-2">
                                        <div className="confirm-detail">
                                            <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="10" height="11"
                                                alt="confirm icon" /></span>
                                            <div className="confirm-detail-title">
                                                {obj.first_name}{' '}{obj.v}
                                            </div>

                                            <div className="confirm-detail-title">

                                                {/* thursday, january 21, 2023  */}
                                                {new Date(obj.timeslot).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                                {' '}
                                                <span>at</span> {obj.timeslot}</div>
                                        </div>
                                        <div className="confirm-detail">
                                            <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="10" height="11"
                                                alt="confirm icon" /></span>
                                            <div className="confirm-detail-title">{location.location_name}</div>
                                            <div className="confirm-detail-desc">
                                                {location.address1},<br />
                                                {location.city}, {location.postal_code}
                                            </div>
                                        </div>
                                        {
                                            obj.services ?
                                                obj.services.map((object, i) =>
                                                    <div className="confirm-detail" key={i}>
                                                        <span className="confirm-detail-icon"><img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="10" height="11"
                                                            alt="confirm icon" /></span>
                                                        <div className="confirm-detail-title">{JSON.parse(object).name} <span>${JSON.parse(object).price}</span></div>
                                                        <div className="confirm-detail-desc">
                                                            {JSON.parse(object).description}
                                                        </div>
                                                    </div>
                                                )

                                                : ''
                                        }

                                    </div>
                                )
                                : ''
                        }

                    </div>
                    <div className="step-btns">
                        <button type="button" className="btn btn-light ms-2" onClick={(e) => back()}>back</button>
                        {/* <a className="btn btn-light ms-2" href="">back</a>
                        <a className="btn btn-primary ms-2" href="">next</a> */}
                        <button type="button" className="btn btn-primary ms-2" onClick={(e) => next()}>{state.isReschedule ? 'submit' : 'next'}</button>
                    </div>
                </div>
            </li>

            <EmptyForms />

            <EmptyCheckout />
        </>
    );
}

export default Confirm;

export function EmptyConfirm() {

    return (
        <>
            <li>
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/confirm-icon.svg`} width="15.16" height="15.16"
                            alt="confirm icon" />
                    </div>
                    <h2 className="steps-box-title">confirm appointment details</h2>
                </div>
            </li>
        </>
    )
}

function ReSchedule(props) {


    return (
        <></>
    )
}


function Confirmed(props) {
    return (
        <>
            <div class="modal" id="onload" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '291px' }}>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 class="modal-title" id="exampleModalLabel">see you soon!</h2>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={`${process.env.PUBLIC_URL}/images/close_modal.svg`} width="11" height="11" alt="close icon" />
                            </button>
                        </div>
                        <div class="modal-body">

                            <div class="max-226 w-100 m-auto">
                                <p class="para text-center">Your appointment has been rescheduled,<br /> we’re looking forward to
                                    seeing you!</p>
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <Link to={`${process.env.PUBLIC_URL}/dashboard/account`} class="btn btn-primary text-center w-100">okay</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}