import axios from "axios";
import Cookies from "js-cookie";
import React, { Component, useState } from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function GiftcardDashboard() {

    const [isActive, setActive] = useState(false);
    const [dbalance, setBalance] = useState(false);

    const shoot = () => {
        setActive(true);

    }

    const currency = {
        style: 'currency',
        currency: 'USD',
    };




    const { register, handleSubmit, setValue, watch,setError,  reset, getValues, formState: { errors } } = useForm();
    const signOut = useSignOut();
    const [profile, setProfile] = useState(false);
    const auth = useAuthUser()

    const balance = (data) => {
        axios.post('client/get-giftcard-balance', { salon_id: auth().salon_id, giftcard_barcode_number: data.barcode })
            .then((res) => {

                // console.log(res.data)
                if (res.data.status) {
                    setBalance(res.data);
                    setActive(true);
                    // toast.success(res.data.message ?? 'something went wrong please try again!', {
                    //     position: toast.POSITION.BOTTOM_CENTER,
                    //     toastId: 'profileupdatesuccess'
                    // })
                    // if (res.data.data.perform_logout) {
                    //     toast.success('redirecting to login page...', {
                    //         position: toast.POSITION.BOTTOM_CENTER,
                    //         toastId: 'redirecttologin'
                    //     })
                    //     setTimeout(() => {
                    //         // signOut();
                    //     }, 6000)
                    // }


                } else {
                    // toast.error(res.data.message ?? 'something went wrong please try again!', {
                    //     position: toast.POSITION.BOTTOM_CENTER,
                    //     toastId: 'profileupdatesuccess'
                    // })
                    setError('barcode', {
                        type: "server",
                        message: res.data.message,
                    })
                }

            })
            .catch((res) => {
                // // console.log(res);

                if (res.response.status == 400) {

                }

                // // console.log(res.response.status);
                if (res.response.status == 422) {

                }

            })
    }

    const giftcardlink = () => {
      return  `https://skoah-ob.webappclouds.com/${Cookies.get('location_slug')}/gift-kards`;
    }


    return (
        <>
            <div className="dashboard-header">
                <div className="dashboard-header-lft">
                    <h1> check giftkard balance</h1>
                </div>
                <div className="dashboard-header-rgt d-flex justify-content-center justify-content-md-end">
                    <Link type="button" className="btn btn-primary" to={giftcardlink()}>purchase a giftkard</Link>
                </div>
            </div>
            <div className="dashbaord-admin-main-content">
                <div className="dashbaord-admin-main-content-card card card-body bg-transparent py-0 px-2">
                    <div className="gfcard-cont-card">
                        <form className="row gx-2" onSubmit={handleSubmit(balance)}>

                            {
                                isActive && dbalance ?
                                    <div >
                                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2 ">
                                            <label className="form-label fw-normal mb-0">balance:</label>
                                            <div className="giftcard-card-dol">{dbalance.data.giftcard_balance}</div>
                                        </div>
                                    </div>
                                    : ''
                            }
                            <div className={'col-12 col-sm-12 col-md-12 form-group mb-2 pt-1'+ (errors?.barcode ? ' invalid-error' : '')}>
                                <label htmlFor="giftkardbarcode" className="form-label">giftkard barcode number</label>
                                <input type="text" {...register('barcode')} className={'form-control'} placeholder="giftkard barcode number" id="giftkardbarcode"  />
                                <div className="invalid-feedback fw-normal position-static">{errors?.barcode && errors?.barcode?.message}</div>

                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-0 text-end">
                                <button type="submit" className="btn btn-primary w-100" >check balance</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}

export default GiftcardDashboard;
