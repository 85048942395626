import axios from 'axios';
import { useStateMachine } from 'little-state-machine';
import React, { Component, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { EmptyCheckout } from './Checkout';
import { EmptyConfirm } from './Confirm';
import { EmptyDateTime } from './DateTime';
import { EmptyForms } from './Forms';
import updateAction from '../../lib/updateAction';
import { EmptyService } from './Service';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Cookies from 'js-cookie';
import { useSignIn } from 'react-auth-kit';


function Location() {

    const [locations, setlocations] = useState(false);
    const [previouslocations, setPreviouslocations] = useState(false);

    const signIn = useSignIn()

    const [filtered, setFiltered] = useState(false);
    const { actions, state } = useStateMachine({ updateAction });
    const { register, handleSubmit, setValue, getValues, reset } = useForm();
    const [selectedLocations, setSelectedLocation] = useState([]);
    const [selectedSaloonId, setSelectedSalonID] = useState(false);

    const navigate = useNavigate();
    const options = [
        { value: 'shop name 1', label: 'shop name 1', state: 'city, state' },
        { value: 'shop name 2', label: 'shop name 2', state: 'city, state' },
        { value: 'shop name 3', label: 'shop name 3', state: 'city, state' }
    ]

    const formatOptionLabel = ({ value, label, state }) => (
        <span className="text">
            <span className='auto-pin'>{label}</span>
            <span className="auto-state" >{state}</span>
        </span>
    );

    // const [options, setOptions] = useState([]);
    const [locationsearch, setLocationsearch] = useState('');

    const next = (data) => {
        // // console.log();
        if (!data.location) {
            toast.error('Please select a location!',
                {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: 'locationrequired'
                });

            return;
        }

        data['step'] = 2;

        actions.updateAction(data);
        var salon = JSON.parse(data.location).salon_id;
        if (salon && salon != Cookies.get('logged_in_salon_id')) {
            axios.post('/client/switch-location', {
                logged_in_salon_id: Cookies.get('logged_in_salon_id'),
                selected_salon_id: salon
            })
                .then(res => {
                    // // console.log(res.data);
                    if (signIn(
                        {
                            token: res.data.data.access_token,
                            expiresIn: res.data.data.expires_at,
                            tokenType: process.env.REACT_APP_AUTH_TOKEN_TYPE,
                            authState: res.data.data.client_info,
                        }
                    )) {
                        Cookies.set('logged_in_salon_id', res.data.data.client_info.salon_id);
                        axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + res.data.data.access_token;
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service');

                    } else {
                    }

                }).catch(res => {
                    // console.log(res);
                })
        } else {
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service');
        }

    };

    useEffect(() => {
        if (locations) {
            setPreviouslocations(
                locations.map((obj) => {
                    return {
                        value: obj,
                        label: obj.location_name,
                        state: obj.city + ' ' + obj.postal_code
                    }
                })
            )
        }

    }, [locations])

    
    useEffect(() => {

    }, [locationsearch])

    const filter = (e) => {
        // console.log(e.target.value)
        let value = e.target.value;
        setLocationsearch(value.replace(/[^A-Za-z0-9 ]/ig, ''))
        if (e.target.value == '' || (e.target.value == ' ' && locationsearch != '') || value.length < 3) {


            setFiltered(false);
        } else {

            // console.log(locationsearch)
            setFiltered(locations.filter(x =>
                x.postal_code.startsWith(e.target.value.toLowerCase())
                // || x.location_name.match(e.target.value)
            ));
        }

    }

    useEffect(() => {
        // console.log(filtered);
    }, [filtered])


    useEffect(() => {

        if (!locations) {
            axios.get('/client/locations')
                .then(res => {
                    setlocations(

                        JSON.parse(
                            JSON.stringify(res.data.data)
                                .toLowerCase()
                        )
                    );


                }).catch(res => {
                    // console.log(locations);
                })
        }


        if (!previouslocations) {
            axios.get('/client/previousBookedlocations')
                .then(res => {
                    // console.log(res.data);
                    var rearrangedrecentlocation =
                        JSON.parse(
                            JSON.stringify(res.data.data)
                                .toLowerCase()
                        );

                    // setPreviouslocations(
                    //     rearrangedrecentlocation.map((obj) => {
                    //         return {
                    //             value: obj,
                    //             label: obj.location_name,
                    //             state: obj.city + ' ' + obj.postal_code
                    //         }
                    //     })
                    // )


                }).catch(res => {
                    // console.log(res);
                })
        }


        if (state.location) {
            setFiltered([JSON.parse(state.location)])
        }

        setSelectedSalonID(Cookies.get('selected_salon_id'));


        reset(state);
        // actions.updateAction({ location: [] })

    }, []);

    useEffect(() => {
        // // console.log(state);
        // console.log(selectedLocations);
        // // console.log(state.location.id);
    }, [selectedLocations])

    useEffect(() => {
        // // console.log(state);
        // console.log(previouslocations);
        // // console.log(state.location.id);
    }, [previouslocations])



    const selectedLocation = (e) => {

        if (e.hasOwnProperty('target') && e.target.checked) {
            var location = e.target.value;
            setValue('salon_id', JSON.parse(location).salon_id);
            setValue('location', location);

        } else {
            // console.log(e);
            var location = e;
            setValue('salon_id', location.value.salon_id);

            setValue('location', JSON.stringify(location.value));
        }

    }

    const setLocation = (e) => {

    }



    return (
        <>
            <li className="active">
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="12.13" height="14.94"
                            alt="location icon" />
                    </div>
                    <form onSubmit={handleSubmit(next)}>

                        <input type={'hidden'} {...register('salon_id')} />
                        <h2 className="steps-box-title">select a location</h2>
                        <div className="bk-ap-steps-box-cont">

                            <div className="form-group mb-1 max-305">
                                <label htmlFor="postalcode" className="form-label fw-normal">enter your postal code</label>
                                <input type="text"
                                    // onKeyUp={e => filter(e)} 
                                    autoComplete='off'
                                    onChange={e => filter(e)}
                                    value={locationsearch}
                                    className="form-control search_icon" placeholder="postal code" id="postalcode" />
                            </div>



                            <div className="selection-list max-305" >

                                {
                                    filtered ?
                                        filtered.map((object, i) =>

                                            <div className="selection-list-item" key={i}>
                                                <h3>{object.location_name}</h3>
                                                <p>{object.address_line_1}</p>
                                                <p>{object.address_line_2}</p>
                                                <div className="selection-list-item-check form-check">
                                                    <input
                                                        id={`custom-checkbox-${object.id}`}
                                                        // name="location"
                                                        type="checkbox"
                                                        // checked={selectedLocations.indexOf(object.id) > -1 ? true : false}
                                                        onClick={e => selectedLocation(e)}
                                                        className="form-check-input"
                                                        aria-label="selection-list-item-check"
                                                        value={JSON.stringify(object)}
                                                        {...register('location')}
                                                    />
                                                </div>
                                            </div>
                                        )

                                        : ''
                                }
                            </div>

                            <div className="form-group mb-1 max-305 white-selct">
                                <label htmlFor="skoahshop" className="form-label fw-normal">skoah shop</label>
                                <Select
                                    defaultValue={state.location ? JSON.parse(state.location) : ''}
                                    formatOptionLabel={formatOptionLabel}
                                    options={previouslocations}
                                    onChange={e => selectedLocation(e)}
                                // {...register('location')}
                                />
                            </div>




                        </div>
                        <div className="step-btns">
                            {/* <!-- <a className="btn btn-light ms-2" href="">back</a> --> */}
                            {/* <a className="btn btn-primary ms-2" href="">next</a> */}
                            <button type="submit" className="btn btn-primary ms-2" {...state.location ? '' : 'Disabled'} >next</button>
                        </div>


                    </form>
                </div>
            </li>

            <EmptyService />

            <EmptyDateTime />

            <EmptyConfirm />

            <EmptyForms />

            <EmptyCheckout />
        </>
    );
}

export default Location;

export function EmptyLocation(params) {
    return (
        <>
            <li >
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/location-icon.svg`} width="12.13" height="14.94"
                            alt="location icon" />
                    </div>
                    <h2 className="steps-box-title">select a location</h2>

                </div>
            </li>
        </>
    )
}

function Locations(props) {
    const { register, handleSubmit } = useForm();
    const { actions, state } = useStateMachine({ updateAction });

    let checked = false
    if (state.location) {
        checked = state.location.id == props.obj.id;
    }

    useEffect(() => {
        // console.log(state);
    }, [state])
    return (
        <>
            <div className="selection-list-item">
                <h3>{props.obj.name}</h3>
                <p>{props.obj.address_1}</p>
                <p>{props.obj.address_2}</p>
                <div className="selection-list-item-check form-check">
                    <input
                        {...register("location")}
                        defaultChecked={checked}
                        type="checkbox"
                        onChange={e => props.selectedLocation(e, props.obj)}
                        className="form-check-input"
                        aria-label="selection-list-item-check"
                    />
                </div>
            </div>
        </>
    )
}