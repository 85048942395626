import React, { Component, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useSignIn, withSignIn } from 'react-auth-kit'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { toast } from 'react-toastify';
import axiosinstance from '../config/axiosinstance';
import Cookies from 'js-cookie';


// TODO IMPLEMENT REFRESH TOKEN IN SOME INTERVAL
function Login() {

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated()

    // console.log(isAuthenticated());



    const signIn = useSignIn()
    const { register, handleSubmit, setError, watch, formState: { errors } } = useForm();


    useEffect(() => {
        if (isAuthenticated()) {
            navigate(process.env.PUBLIC_URL+'/dashboard');
        }
    });


    async function onSubmit(formData) {

        // e.preventDefault()
        formData.salon_id = process.env.REACT_APP_SALOON_ID

        await axios.post(`client/login`, formData)
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    if (signIn(
                        {
                            token: res.data.token,
                            expiresIn: res.data.expiresIn,
                            tokenType: process.env.REACT_APP_AUTH_TOKEN_TYPE,
                            authState: res.data.authState,
                            // saloon: res.data.authState.salon_id
                            // REACT_APP_AUTH_TOKEN_TYPE
                            // refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
                            // refreshTokenExpireIn: res.data.refreshTokenExpireIn     // Only if you are using refreshToken feature
                        }
                    )) { // Only if you are using refreshToken feature
                        // if (process.env.REACT_APP_AUTH_TYPE == 'cookie') {
                        // if (Cookies.get(process.env.REACT_APP_AUTH_NAME)) {
                        Cookies.set('logged_in_salon_id', res.data.authState.salon_id);
                        Cookies.set('location_slug', res.data.location_slug);
                        axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + res.data.token;
                        // // console.log(axios.defaults.headers.common);
                        // }
                        // }
                        // Redirect or do-something
                        navigate(process.env.PUBLIC_URL+'/dashboard');
                    } else {
                        //Throw error
                        // toast.error("Error Notification !", {
                        //     position: toast.POSITION.TOP_LEFT
                        //   });

                    }
                }
            })
            .catch((error) => {
                // // console.log(error);
                if (error.response.status == 422) {
                    const serrors = error.response.data.errors;
                    // console.log(serrors);
                    if (typeof serrors == 'string') {
                        setError('email', {
                            type: "server",
                            message: serrors,
                        });
                    } else {
                        if (serrors.email) {
                            setError('email', {
                                type: "server",
                                message: serrors.email[0],
                            });
                        }
                        if (serrors.password) {
                            // // console.log(serrors.password);
                            setError('password', {
                                type: "server",
                                message: serrors.password[0],
                            });
                        }
                    }

                }
                // // console.log(errors);
                // toast.error(error.response.data.message, {
                //     position: toast.POSITION.BOTTOM_CENTER
                // });


            });
    }


    return (
        <>
            <div className="login-bg">
                <div className="signin-cont">
                    <div className="signin-cont-in">
                        <div className="signin-cont-in-lft">
                            <div className="signin-cont-in-lft-in">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} width="183.12" height="86" alt="skoah Logo" />
                                <div className="powered-by">
                                    <img src={`${process.env.PUBLIC_URL}/images/powered-by.svg`} width="129.37" height="29.66" alt="powered by saloncloudsplus img" />
                                </div>
                            </div>
                        </div>
                        <div className="signin-cont-in-rgt">
                            <div className="signin-cont-in-rgt-in">
                                <h1>welcome</h1>
                                <h2>don’t have an account? <Link to={`${process.env.PUBLIC_URL}/register`} aria-label="click to sign-up" role="link">sign up</Link></h2>
                                <form className="m-0 needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                                    <div className={'form-group mb-1 ' + (errors.email ? 'invalid-error' : '')}>
                                        <label htmlFor="email" className="form-label">email</label>
                                        <input type="email" {...register("email", { required: true })} className="form-control" placeholder="email" id="email" required />

                                        <div class="invalid-feedback fw-normal position-static">{errors.email && errors.email.message}</div>
                                    </div>
                                    <div className={'form-group mb-1 ' + (errors.password ? 'invalid-error' : '')}>
                                        <label htmlFor="password" className="form-label">password</label>
                                        <input type="password" {...register("password", { required: true })} className="form-control" placeholder="password" id="password" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.password && errors.password.message}</div>
                                    </div>
                                    <div className="form-group my-2">
                                        <button type="submit" className="btn btn-primary">login</button>
                                        {/* <a href="dashboard.html" className="btn btn-primary" aria-label="click to login">log in</a> */}
                                    </div>
                                    <div className="form-check mt-3">
                                        <input tabIndex="-1" {...register("remember_token")} type="checkbox" className="form-check-input" id="RememberMe" />
                                        <label tabIndex="0" aria-label="click to remember me" className="form-check-label" htmlFor="RememberMe">remember me</label>
                                    </div>
                                    <ul className="forgot-list">
                                        <li><Link to={`${process.env.PUBLIC_URL}/forget-password`} aria-label="click to forgot password" role="link">forgot password</Link></li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-footer">
                    <div className="signin-footer-lft">© skoah 2023 | designed and developed by webappclouds</div>
                    <div className="signin-footer-rgt"><a href="">terms & conditions</a> <a href="">privacy policy</a></div>
                </div>
            </div>
        </>
    );
}

// export default Login;\
export default withSignIn(Login)
