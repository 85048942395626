import { useStateMachine } from 'little-state-machine';
import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmptyCheckout } from './Checkout';
import { EmptyConfirm } from './Confirm';
import { EmptyDateTime } from './DateTime';
import { EmptyForms } from './Forms';
import { EmptyLocation } from './Location';
import updateAction from '../../lib/updateAction';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';


export const ConnectForm = ({ children }) => {
    const methods = useFormContext();

    return children({ ...methods });
};

function Service() {

    const navigate = useNavigate();
    const { actions, state } = useStateMachine({ updateAction });
    const { client } = useParams();
    // // console.log(client);

    // const { register, handleSubmit, setValue, watch, reset, getValues, control } = useForm();
    const methods = useForm();

    const control = methods.control;
    // { fields, append, prepend, remove, swap, move, insert }
    const fieldarray = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "group", // unique name for your Field Array
        rules: {
            validate: (fieldArrayValues) => {
                // check that sum of all fields == 100
                // console.log(fieldArrayValues);
            },
        }
    });

    const [selectedservices, setSelectedServices] = useState(false);
    const [services, setServices] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(false);
    const [activeClientForServiceSelection, setActiveClientForServiceSelection] = useState(false);
    const [clientForServiceSelection, setClientForServiceSelection] = useState(false);

    const [serviceTimes, setServiceTimes] = useState(false);

    const back = (data) => {

        if (state.bookingfor == 'group') {

            for (var i = 0, iLen = state.group.length; i < iLen; i++) {

                if (state.group[i].client_id == client) {
                    // console.log(i)
                    if (typeof state.group[i - 1] != 'undefined') {
                        setActiveClientForServiceSelection(state.group[i - 1]);
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + state.group[i - 1].client_id)
                    } else {
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/location')
                    }

                };
            }
        }

        if (state.bookingfor == 'someone') {
            setActiveClientForServiceSelection(state.guest);
            // navigate(process.env.PUBLIC_URL+'/dashboard/book-appointment/service/' + state.guest.client_id)
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/location');
        }

        if (state.bookingfor == 'myself') {
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/location');

        }

    };

    const newguest = {
        "first_name": "",
        "last_name": "",
        "phone": "",
        "email": "",
        "promo_code": ""
    }



    useEffect(() => {

        setSelectedClientId(client)
        // // console.log(state.bookingfor);
        if (fieldarray) {
            fieldarray.append(newguest);

        }

        if (!state.group || !state.guest && !state.bookingfor) {
            setShowModal(true);
        }

        methods.reset(state);

        if (state.bookingfor == 'group' && !client && state.group && state.group.length > 0) {
            setActiveClientForServiceSelection(state.group[0]);
            // console.log('re to tk')
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + state.group[0].client_id);
        }

        if (client) {
            setShowModal(false);

            switch (state.bookingfor) {
                case 'myself':

                    break;

                case 'someone':
                    setActiveClientForServiceSelection(state.guest);
                    break;

                case 'group':
                    setActiveClientForServiceSelection(
                        state.group.filter(x =>
                            x.client_id == client
                        )[0]
                    );
                    // setActiveClientForServiceSelection(state.guest);
                    break;

                default:
                    break;
            }
        }

    }, [])

    useEffect(() => {
        // console.log(activeClientForServiceSelection)
        if (activeClientForServiceSelection) {
            methods.setValue('service', activeClientForServiceSelection.services)

        }

    }, [activeClientForServiceSelection])

    const appendGroupInput = () => {
        fieldarray.append(newguest);
    }

    const removeGroupInput = (index) => {
        fieldarray.remove(index);
    }



    const next = (data) => {


        if (!data.bookingfor) {
            toast.error('Please choose who are you booking for?', {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: 'bookingforrequired'
            });
            return;
        }

        // if (!data.service) {
        //     toast.error('Please choose service?', {
        //         position: toast.POSITION.BOTTOM_CENTER,
        //         toastId: 'servicerequired'
        //     });
        //     return;
        // }

        if (data.bookingfor == 'group') {


            data.group = state.group.filter(function (obj) {

                if (obj.client_unique_id == activeClientForServiceSelection.client_unique_id) {
                    obj.services = data.service;
                    return obj;
                } else {
                    return obj;
                }
            });

            data.service = null;

            actions.updateAction(data);


            var c = 0;


            for (var i = 0; i < state.group.length; i++) {
                if (state.group[i].hasOwnProperty('services') && state.group[i]['client_id'] === client) {

                    if (typeof state.group[i + 1] !== "undefined") {
                        setActiveClientForServiceSelection(state.group[i + 1]);
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + state.group[i + 1].client_id)
                    } else {
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time/' + state.group[0]['client_id']);
                    }

                }
            }

        }

        if (data.bookingfor == 'someone') {
            if (data.guest.services == '') {
                toast.error('Please choose service!',
                    { position: toast.POSITION.BOTTOM_CENTER, toastId: 'servicerequired' }
                );
                return;
            }

            data.guest = state.guest;
            data.guest.services = data.service;

            data.service = null;

            actions.updateAction(data);

            if (state.guest.hasOwnProperty("services")) {
                navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time/' + state.guest.client_id);
            }

        }

        if (data.bookingfor == 'myself') {
            if (data.service == '') {
                toast.error('Please choose service!',
                    { position: toast.POSITION.BOTTOM_CENTER, toastId: 'servicerequired' }
                );
                return;
            }

            actions.updateAction(data);

            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time');
        }



    };

    const nextServiceClient = (db, key, value) => {
        for (var i = 0; i < db.length; i++) {
            // console.log(db[i][key]);
            // console.log(value);
            // console.log(db[i][key] === value);
            if (db[i][key] === value) {
                return db[i + 1] && db[i + 1].value;
            } else { return 0 }
        }
    };

    const watchBookingFor = methods.watch("bookingfor");
    const watchGuest = methods.watch("guest");

    const watchServiceTime = methods.watch("servicetime");

    useEffect(() => {
        // // console.log(watchGuest);
    }, [watchGuest]);

    useEffect(() => {
        // console.log(watchServiceTime);
        if (watchServiceTime) {
            setClicked(0)
        }
    }, [watchServiceTime]);

    useEffect(() => {

        if (!state.location) {
            navigate(process.env.PUBLIC_URL + '/dashboard')
        } else {
            axios.get('/client/sessionTypes?salon_id=' + JSON.parse(state.location).salon_id)
                .then(res => {
                    setServices(
                        JSON.parse(
                            JSON.stringify(res.data.data)
                                .toLowerCase()
                        )
                    );
                })

            axios.get('/client/get-service-times?salon_id=' + JSON.parse(state.location).salon_id)
                .then(res => {
                    if (res.data) {
                        setServiceTimes(
                            JSON.parse(
                                JSON.stringify(res.data.data)
                                    .toLowerCase()
                            )
                        );
                    }

                })
        }


    }, []);

    // // console.log(activeClientForServiceSelection);

    React.useEffect(() => {
        // // console.log(methods.watch())
        // const subscription = methods.watch((value, { name, type }) =>  console.log(value, name, type));
        // return () => subscription.unsubscribe();
    }, [methods.watch]);

    useEffect(() => {
        // // console.log(watchBookingFor)
    }, [watchBookingFor])

    const Setbookingfor = () => { }

    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    // ACCORDION
    const [clicked, setClicked] = useState("0");
    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    return (
        <>

            <EmptyLocation />


            <li className="active">
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="15.41" height="15.88"
                            alt="service icon" />
                    </div>
                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(next)}>
                            <input type={'hidden'} {...methods.register('guest')} />
                            {/* <button type='button' onClick={openModal}>Open Modal</button> */}
                            {showModal ? <Modal setShowModal={setShowModal} content={<Content
                                clients={{ clientForServiceSelection, setClientForServiceSelection }}
                                closeModal={closeModal}
                                activeclient={{ activeClientForServiceSelection, setActiveClientForServiceSelection }}
                                removeGroupInput={removeGroupInput}
                                appendGroupInput={appendGroupInput}
                                methods={methods}
                                fieldarray={fieldarray}
                            />} /> : null}


                            <h2 className="steps-box-title">select a service</h2>
                            <div className="bk-ap-steps-box-cont">

                                {state.bookingfor != 'myself' && activeClientForServiceSelection ?
                                    <div className="form-group mb-3 ">
                                        <label className="form-label fw-normal">select a service for</label>
                                        <div className="checked-btns">
                                            <h4 className="steps-sub-title">
                                                {activeClientForServiceSelection.first_name + ' ' +
                                                    activeClientForServiceSelection.last_name}
                                            </h4>
                                        </div>



                                    </div>
                                    : ''}




                                <div class="form-group mb-3 ">
                                    <label class="form-label fw-normal">select your service time</label>
                                    <div class="checked-btns">

                                        {
                                            serviceTimes ?
                                                serviceTimes.map((time, i) =>
                                                    <>
                                                        <input type="radio" class="btn-check" {...methods.register(`servicetime`)} value={time.duration} id={'minute' + i} autocomplete="off" />
                                                        <label class="btn btn-primary me-2" for={'minute' + i}>{time.title}</label>
                                                    </>
                                                )
                                                : ''
                                        }
                                    </div>
                                </div>

                                <div className="form-group mb-1 mt-2" id="a-group-data">
                                    <label className="form-label w-100 fw-normal">select your service</label>
                                    <div className="max-365">
                                        <div className="accordion" id="accordionExample">

                                            {

                                                services ?
                                                    Object.keys(services).map((object, i) =>

                                                        <div className="accordion-item" key={i}>
                                                            <h2 className="accordion-header" id={'heading' + i}>
                                                                <button className="accordion-button" type="button" onClick={() => handleToggle(i)} data-bs-toggle="collapse" data-bs-target={'#collapse' + i} aria-expanded="true" aria-controls={'collapse' + i}>
                                                                    {object}
                                                                </button>
                                                            </h2>
                                                            <div id={'collapse' + i} className={'accordion-collapse collapse' + (clicked === i ? "show" : "")} aria-labelledby={'heading' + i} data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">

                                                                    {
                                                                        Object.keys(services[object]).map((item, ii) => {
                                                                            return (services[object][item].default_time_length == watchServiceTime) ?
                                                                                <>
                                                                                    <div className="form-check mt-3" key={ii}>
                                                                                        <input tabIndex="0" {...methods.register(`service`)} value={JSON.stringify(services[object][item])} type="checkbox" className="form-check-input" id={'featurelist' + services[object][item].id} />

                                                                                        <label tabIndex="0" aria-label="click to remember me" className="form-check-label" htmlFor={'featurelist' + services[object][item].id}>{services[object][item].name} <span>${services[object][item].price}</span></label>
                                                                                    </div>
                                                                                    <div className="accordion-body-desc">{services[object][item].description}</div>
                                                                                </>
                                                                                : ''
                                                                        }

                                                                        )
                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    : ""
                                            }

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="step-btns">
                                <button type="button" className="btn btn-light ms-2" onClick={(e) => back()}>back</button>
                                {/* <a className="btn btn-light ms-2" href="">back</a>
                        <a className="btn btn-primary ms-2" href="">next</a> */}
                                <button type="submit" className="btn btn-primary ms-2" >next</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </li>

            <EmptyDateTime />

            <EmptyConfirm />

            <EmptyForms />

            <EmptyCheckout />



        </>
    );
}

export default Service;

export function EmptyService() {
    return (
        <>
            <li >
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/service-icon.svg`} width="15.41" height="15.88"
                            alt="service icon" />
                    </div>
                    <h2 className="steps-box-title">select a service</h2>
                </div>
            </li>
        </>
    )
}



function Content(props) {


    const [clicked, setClicked] = useState("0");
    const fields = props.fieldarray.fields;
    const { actions, state } = useStateMachine({ updateAction });
    const navigate = useNavigate();


    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    const submitLocally = () => {

        // console.log(props.methods.getValues())
        var data = props.methods.getValues()
        if (data.bookingfor == 'myself') {
            props.closeModal();
            props.activeclient.setActiveClientForServiceSelection(null);
            actions.updateAction({ bookingfor: data.bookingfor });
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/');
        } else {

            if (data.bookingfor == 'group') {
                // console.log(data.group)
                if (data.group && data.group.length > 0) {


                    if (data.group[0].first_name == '' || data.group[0].email == '' || data.group[0].phone == '') {
                        toast.error('Please add atleast one guest!', {
                            position: toast.POSITION.BOTTOM_CENTER,
                            toastId: 'guestrequired'

                        });
                        return;
                    }



                }
            }
            axios.post('client/createBookingClient', { bookingfor: data.bookingfor, group: data.group, guest: data.guest, salon_id: data.salon_id })
                .then((res) => {
                    // console.log(res);

                    if (res.data.hasOwnProperty('status') && !res.data.status) {

                        Object.keys(res.data.errors).map((object, i) => {
                            // console.log(res.data.errors[object])

                            res.data.errors[object].map((msg) => {
                                toast.error(msg, {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    toastId: 'createclienterror'
                                })
                            });
                        })
                        return;
                    }

                    // 
                    props.closeModal()
                    switch (data.bookingfor) {

                        case 'someone':
                            props.activeclient.setActiveClientForServiceSelection(res.data.someone);

                            // // console.log(props.activeclient.activeClientForServiceSelection);
                            // fields.setValue('', res.data.someone)
                            props.clients.setClientForServiceSelection([res.data.guest])
                            actions.updateAction({ guest: res.data.someone, bookingfor: data.bookingfor });

                            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + res.data.someone.client_id);
                            break;

                        case 'group':

                            props.clients.setClientForServiceSelection(res.data.group)
                            actions.updateAction({ group: res.data.group, bookingfor: data.bookingfor });
                            props.activeclient.setActiveClientForServiceSelection(res.data.group[0]);
                            props.closeModal()
                            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + res.data.group[0].client_id);
                            break;

                        default:
                            break;
                    }


                })
                .catch((res) => {
                    // console.log(res);
                    // setCheckoutSubmitted(false)

                    if (res.response.status == 400) {

                        if (Array.isArray(res.response.data)
                        ) {
                            res.response.data.map((err) => {
                                toast.error(err.message, {
                                    position: toast.POSITION.BOTTOM_CENTER,

                                })
                            })
                        } else {
                            toast.error(res.response.data.message, {
                                position: toast.POSITION.BOTTOM_CENTER
                            })
                        }

                    }

                    // console.log(res.response.status);
                    if (res.response.status == 422) {
                        const serrors = res.response.data.errors;
                        toast.error(res.response.data.message ?? 'something went wrong please try again!', {
                            position: toast.POSITION.BOTTOM_CENTER
                        })


                        if (serrors?.CardHolder) {
                            props.methods.setError('CardHolder', {
                                type: "server",
                                message: serrors.CardHolder[0],
                            });
                        }

                        // 
                    }

                })
        }

    }

    useEffect(() => {

        setClicked(fields.length - 1)
    }, [fields])

    const back = () => {
        // props.closeModal();
        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/location');
    }


    const watchBookingFor = props.methods.watch('bookingfor');

    useEffect(() => {
        // console.log(watchBookingFor);
        props.methods.reset(state);


    }, [])

    const watchphone = props.methods.watch('guest.phone');


    useEffect(() => {
        if (watchphone) {

            props.methods.setValue('guest.phone', watchphone.replace(/[^0-9]/ig, '').substr(0, 10))
        }
    }, [watchphone])

    const [tagGroupPhoneVal, setGroupPhoneVal] = useState("");


    function onChangePhoneInput(e) {
        // // console.log(e.target.name);
        // setGroupPhoneVal(e.target.value.replace(/[^0-9]/ig, '').substr(0, 10));
        props.methods.setValue(e.target.name, e.target.value.replace(/[^0-9]/ig, '').substr(0, 10));

    }

    useEffect(() => {

    }, [tagGroupPhoneVal])

    return (
        <>

            <ConnectForm>
                {

                    ({ register, watch }) =>

                        <div class="modal-content-main" style={{ width: '100%' }}>
                            {/* { // console.log(watch) } */}
                            <div class="modal-content mb-3">
                                <div class="modal-header">
                                    <h2 class="modal-title" id="exampleModalLabel">who are you booking for?</h2>
                                </div>
                                <div class="modal-body">
                                    <div class="max-226 w-100 m-auto" style={{ maxWidth: '242px' }}>
                                        <p class="para text-center">using the options below, please let us know who your appointment is for</p>
                                    </div>
                                    <div class="checked-btns text-center">
                                        {/* <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off" checked /> */}
                                        <input type="radio" class="btn-check" {...register("bookingfor")} value={'myself'} id="option1" autocomplete="off" />
                                        <label class="btn btn-primary me-1" for="option1">myself</label>

                                        {/* <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off" /> */}
                                        <input type="radio" class="btn-check" {...register("bookingfor")} value={'someone'} id="option2" autocomplete="off" />

                                        <label class="btn btn-primary me-1" for="option2">someone else</label>

                                        {/* <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off" /> */}
                                        <input type="radio" class="btn-check" {...register("bookingfor")} value={'group'} id="option3" autocomplete="off" />

                                        <label class="btn btn-primary me-0" for="option3">a group</label>
                                    </div>
                                </div>
                            </div>
                            {
                                watchBookingFor == 'myself' ?
                                    <div class="modal-content mb-3">
                                        <div class="modal-header" style={{display: 'block'}}>
                                            <h2 class="modal-title" >have a promo code? enter it below!</h2>
                                            <p class="para text-center">price will be reflected at the time of check out at your local facial shop.</p>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row g-2 max-480">

                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                    <label for="promocode" class="form-label">promo code</label>
                                                    <input type="text" class="form-control form-control-gray" {...register(`promo_code`)} placeholder="promo code" id="promocode" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                watchBookingFor == 'someone' ?
                                    <div class="modal-content mb-3">
                                        <div class="modal-header">
                                            <h2 class="modal-title" >guest information</h2>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row g-2 max-480">
                                                <div class="col-12 col-sm-12 col-md-6 form-group mb-1">
                                                    <label for="firstname" class="form-label">first name</label>
                                                    <input type="text" class="form-control form-control-gray" {...register("guest.first_name")} placeholder="first name" id="firstname" required />
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 form-group mb-1">
                                                    <label for="lastname" class="form-label">last name</label>
                                                    <input type="text" class="form-control form-control-gray" {...register("guest.last_name")} placeholder="last name" id="lastname" required />
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                    <label for="phonenumber" class="form-label">phone number</label>
                                                    <input type="text" class="form-control form-control-gray" {...register("guest.phone")} placeholder="phone number" id="phonenumber" required />
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                    <label for="email" class="form-label">email</label>
                                                    <input type="email" class="form-control form-control-gray" {...register("guest.email")} placeholder="email" id="email" required />
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                    <label for="promocode" class="form-label">promo code</label>
                                                    <input type="text" class="form-control form-control-gray" {...register(`guest.promo_code`)} placeholder="promo code" id="promocode" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                watchBookingFor == 'group' ?
                                    <div class="guest-list-accord" id="accordionGuest">

                                        {
                                            fields ?
                                                fields.map((group, index) => (
                                                    <div class="modal-content mb-3">
                                                        <div class="modal-header" id="heading1" data-bs-toggle="collapse" onClick={() => handleToggle(index)} data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapseOne">
                                                            <h2 class="modal-title" >guest {index + 1} information</h2>
                                                        </div>
                                                        <div id="collapse1" class={`modal-body accordion-collapse collapse ${clicked === index ? "show" : ""} `} aria-labelledby="heading1" data-bs-parent="#accordionGuest">
                                                            <div class="row g-2 max-480">
                                                                <div class="col-12 col-sm-12 col-md-6 form-group mb-1">
                                                                    <label for="firstname" class="form-label">first name</label>
                                                                    <input type="text" class="form-control form-control-gray" {...register(`group.${index}.first_name`)} placeholder="first name" id="firstname" required />
                                                                </div>
                                                                <div class="col-12 col-sm-12 col-md-6 form-group mb-1">
                                                                    <label for="lastname" class="form-label">last name</label>
                                                                    <input type="text" class="form-control form-control-gray" {...register(`group.${index}.last_name`)} placeholder="last name" id="lastname" required />
                                                                </div>
                                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                                    <label for="phonenumber" class="form-label">phone number</label>
                                                                    <input type="text" onInput={(e) => onChangePhoneInput(e)}
                                                                        class="form-control form-control-gray" {...register(`group.${index}.phone`)} placeholder="phone number" id="phonenumber" required />
                                                                </div>
                                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                                    <label for="email" class="form-label">email</label>
                                                                    <input type="email" class="form-control form-control-gray" {...register(`group.${index}.email`)} placeholder="email" id="email" required />
                                                                </div>
                                                                <div class="col-12 col-sm-12 col-md-12 form-group mb-1">
                                                                    <label for="promocode" class="form-label">promo code</label>
                                                                    <input type="text" class="form-control form-control-gray" {...register(`group.${index}.promo_code`)} placeholder="promo code" id="promocode" required />
                                                                </div>
                                                            </div>
                                                            {
                                                                index != 0 ? <div class="pt-1">
                                                                    <a type="button" onClick={() => props.removeGroupInput(index)} class="del-guest text-danger">delete guest {index + 1}</a>
                                                                </div> : ''
                                                            }
                                                        </div>
                                                    </div>

                                                ))
                                                : ''
                                        }


                                        <div class="step-btns mb-2 modal-content modal-content-nobg">
                                            <button class="btn btn-white ms-2" type="button" onClick={() => props.appendGroupInput()}>add another guest</button>
                                        </div>
                                    </div>
                                    : ''
                            }


                            <div class="step-btns modal-content modal-content-nobg">
                                <button type="button" class="btn btn-light ms-2" onClick={() => back()} >back</button>
                                {/* <a class="btn btn-light ms-2" href="">back</a> */}
                                {/* <a class="btn btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#roomSelectionModal" data-bs-dismiss="modal">next</a> */}
                                <button type="button" class="btn btn-primary ms-2" onClick={() => submitLocally()} disabled={watchBookingFor == null ? 'disabled' : ''} >next</button>
                            </div>
                        </div>

                }
            </ConnectForm>


        </>
    )
}