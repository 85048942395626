import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Component, useEffect, useState } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';
import parse from 'html-react-parser';


function Account() {

    const [profile, setProfile] = useState(false);
    const auth = useAuthUser()


    const getProfile = () => {
        axios.get('/client/getClientProfile?salon_id=' + auth().salon_id)
            .then(res => {
                setProfile(res.data.data);
            })
    }

    useEffect(() => {

        if (!profile) { getProfile() }
    }, [])

    return (
        <>
            <div className="dashboard-header">
                <div className="dashboard-header-lft">
                    <h1> my account</h1>
                </div>

            </div>
            <div
                className="dashbaord-admin-main-content-card card card-body bg-transparent p-0 dashbaord-charts-page">
                <div className="dashbaord-admin-main-content-card-scroll hide-scrollbar">
                    <div className="myaccount-cont">
                        <div className="row gx-2">
                            <div className="col-12 col-sm-12 col-md-4">
                                <div className="myaccount-cont-card">
                                    <h2 className="steps-box-title px-1 mb-2">account balance</h2>
                                    <div className="card myaccount-card">
                                        your account balance is &nbsp;<strong> {profile.account_balance}</strong>&nbsp;
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                                <div className="myaccount-cont-card">
                                    <h2 className="steps-box-title px-1 mb-2">membership status</h2>
                                    <div className="card myaccount-card">
                                        {
                                            profile.is_member
                                                ?
                                                <>you are currently enrolled as a &nbsp;<strong> member</strong>&nbsp;</>
                                                :
                                                <>you are currently &nbsp;<strong>not a member</strong>&nbsp;</>
                                        }

                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                                <div className="myaccount-cont-card">
                                    <h2 className="steps-box-title px-1 mb-2">billing</h2>
                                    <div className="card myaccount-card">
                                        {profile.billing_info_html_text ? parse(profile.billing_info_html_text) : 'You have no pending payments.”'}&nbsp;
                                    </div>
                                </div>

                            </div>
                            <ProfileInfo profile={profile} />

                            <CReditCard />

                            <Appointments />



                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ProfileInfo(props) {

    const { register, handleSubmit, setValue, watch, reset, getValues } = useForm();
    const signOut = useSignOut();
    const [profile, setProfile] = useState(false);
    const auth = useAuthUser()

    const update = (data) => {
        axios.post('client/updateClientProfile', data)
            .then((res) => {

                if (res.data.status) {
                    toast.success(res.data.message ?? 'something went wrong please try again!', {
                        position: toast.POSITION.BOTTOM_CENTER,
                        toastId: 'profileupdatesuccess'
                    })
                    if (res.data.data.perform_logout) {
                        toast.success('redirecting to login page...', {
                            position: toast.POSITION.BOTTOM_CENTER,
                            toastId: 'redirecttologin'
                        })
                        setTimeout(() => {
                            signOut();
                        }, 5000)
                    }


                } else {
                    toast.error(res.data.message ?? 'something went wrong please try again!', {
                        position: toast.POSITION.BOTTOM_CENTER,
                        toastId: 'profileupdateerror'
                    })
                }

            })
            .catch((res) => {

                if (res.response.status == 400) {

                }

                // console.log(res.response.status);
                if (res.response.status == 422) {

                }

            })
    }

    const getProfile = () => {
        axios.get('/client/getClientProfile?salon_id=' + auth().salon_id)
            .then(res => {
                setProfile(res.data.data);
            })
    }

    useEffect(() => {

        setProfile(props.profile);

    }, [props.profile])

    useEffect(() => {
        reset({
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            phone: profile.phone,

        });
    }, [profile])

    const watchphone = watch('phone');



    useEffect(() => {
        if (watchphone) {

            setValue('phone', watchphone.replace(/[^0-9]/ig, '').substr(0, 10))
        }
    }, [watchphone])


    return (
        <>

            <div className="col-12 col-sm-12 col-md-4">
                <div className="myaccount-cont-card">
                    <h2 className="steps-box-title px-1 mb-2">profile information</h2>
                    <form className="row gx-2" onSubmit={handleSubmit(update)}>
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                            <label for="firstname" className="form-label">first name</label>
                            <input type="text" className="form-control" {...register(`first_name`)} placeholder="first name" id="firstname" required />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                            <label for="lastname" className="form-label">last name</label>
                            <input type="text" className="form-control" {...register(`last_name`)} placeholder="last name" id="lastname" required />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                            <label for="email" className="form-label">email</label>
                            <input type="text" className="form-control" {...register(`email`)} placeholder="email" id="email" required />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                            <label for="phone" className="form-label">phone number</label>
                            <input type="text" className="form-control" {...register(`phone`)} placeholder="phone" id="phone" required />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 form-group mb-3">
                            <label for="password" className="form-label">password</label>
                            <input type="password" className="form-control" {...register(`password`)} placeholder="password" id="password" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-0 text-end">
                            <button type="submit" className="btn btn-primary">update</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

function CReditCard(props) {

    const { register, handleSubmit, setValue, setError, watch, reset, getValues, formState: { errors } } = useForm();

    const [profile, setProfile] = useState(false);
    const auth = useAuthUser()


    const update = (data) => {

        // console.log(data.payment_info.card_number.length); return;

        if (data.payment_info.card_number.length < 16) {
            setError('payment_info.card_number', {
                type: "server",
                message: 'card number should be minimum 16 digits',
            });
            return;
        }
        axios.post('client/updateClientCreditCard', data)
            .then((res) => {

                // console.log();
                if (!res.data.status) {
                    setError('payment_info.card_number', {
                        type: "server",
                        message: res.data.message,
                    });
                    // toast.error(res.data.message ?? 'something went wrong please try again!', {
                    //     position: toast.POSITION.BOTTOM_CENTER,
                    //     toastId: 'creditcardupdateerr'
                    // })
                } else {
                    toast.success(res.data.message ?? 'something went wrong please try again!', {
                        position: toast.POSITION.BOTTOM_CENTER,
                        toastId: 'creditcardupdatesucc'
                    })
                }

            })
            .catch((res) => {
                // console.log(res);

                if (res.response.status == 400) {

                }

                // console.log(res.response.status);
                if (res.response.status == 422) {
                    // const serrors = res.response.data.errors;
                    // setError('carderror', {
                    //     type: "server",
                    //     message: res.response.data.errors,
                    // });
                    Object.keys(res.response.data.errors).map((object, i) => {
                        // console.log(res.response.data.errors[object])

                        res.response.data.errors[object].map((msg) => {
                            // toast.error(msg, {
                            //     position: toast.POSITION.BOTTOM_CENTER,
                            //     toastId: object
                            // })

                            // console.log(msg);
                            // console.log(object.split(".")[1])
                            setError(object, {
                                type: "server",
                                message: msg,
                            });
                            return;
                        });
                    })
                }

            })
    }

    const getProfile = () => {
        axios.get('/client/getClientCC?salon_id=' + auth().salon_id)
            .then(res => {
                setProfile(res.data.data);
            })
    }

    useEffect(() => {

        if (profile) {
            reset(profile);
            setIsEditing(false);
        } else {
            getProfile();
        }
        setValue('salon_id', Cookies.get('saloon'))
    }, [])

    useEffect(() => {
        // console.log(errors);
    }, [errors])

    useEffect(() => {
        reset(profile);
    }, [profile])

    const watchcreditcard = watch('payment_info.card_number');

    const watchcvv = watch('payment_info.cvv');

    const watchexpirydate = watch('payment_info.exp_date');

    useEffect(() => {
        if (watchcreditcard) {
            setValue('payment_info.card_number', watchcreditcard.replace(/[^0-9*]/ig, '').substr(0, 16));
        }

    }, [watchcreditcard]);

    useEffect(() => {
        if (watchcvv) {
            setValue('payment_info.cvv', watchcvv.replace(/[^0-9*]/ig, '').substr(0, 4));
        }

    }, [watchcvv])

    useEffect(() => {
        // console.log(e);
        var watchexpirydate2 = '';
        if (watchexpirydate) {
            setValue('payment_info.exp_date', watchexpirydate2 ? watchexpirydate2.replace(/[^0-9/]/ig, '').substr(0, 7) : watchexpirydate.replace(/[^0-9/]/ig, '').substr(0, 7));
        }



    }, [watchexpirydate])

    const watchphone = watch('payment_info.phone_number');


    useEffect(() => {
        if (watchphone) {

            setValue('payment_info.phone_number', watchphone.replace(/[^0-9]/ig, '').substr(0, 10))
        }
    }, [watchphone])

    const [isUpdating, setIsEditing] = useState(false);


    const toggleEditing = () => {
        if (isUpdating) {
            reset(profile)
            setIsEditing(false);
        } else {
            reset(
                {
                    payment_info: {
                        name_on_card: '',
                        card_number: '',
                        exp_date: '',
                        cvv: ''
                    }
                }
            );
            setIsEditing(true);
        }
    }

    return (
        <>
            <div className="col-12 col-sm-12 col-md-4">
                <div className="myaccount-cont-card">
                    <h2 className="steps-box-title px-1 mb-2">credit card <span onClick={e => toggleEditing()} style={{ color: '#83A3D6', textAlign: 'right', float: 'right', fontSize: '10px' }}> {isUpdating ? 'view' : 'update'}</span></h2>
                    <form className="row gx-2" onSubmit={handleSubmit(update)}>
                        <input type="hidden" {...register('salon_id')} />
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                            <label for="nameoncard" className="form-label">name on card</label>
                            <input readOnly={!isUpdating} type="text" className="form-control" {...register(`payment_info.name_on_card`)} placeholder="name on card" id="nameoncard" required />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                            <label for="cardnumber" className="form-label">card number</label>
                            <input readOnly={!isUpdating} type="text" className="form-control" {...register(`payment_info.card_number`)} placeholder="card number" id="cardnumber" required />
                            <div className="invalid-feedback">{errors?.payment_info?.card_number && errors?.payment_info?.card_number?.message}</div>

                        </div>
                        <div className="col-12 col-sm-12 col-md-6 form-group mb-2">
                            <label for="expirationdate" className="form-label">expiration date</label>
                            <input readOnly={!isUpdating} type="text" className="form-control" {...register(`payment_info.exp_date`)} placeholder="MM/YYY" id="expirationdate" required />
                            {/* <input type="text" className="form-control" {...register(`exp_year`)} placeholder="Year" id="expirationdate" required /> */}

                        </div>
                        <div className="col-12 col-sm-12 col-md-6 form-group mb-2">
                            <label for="cvv" className="form-label">cvv</label>
                            <input readOnly={!isUpdating} type="text" className="form-control" {...register(`payment_info.cvv`)} placeholder="cvv" id="cvv" required />
                            <div className="invalid-feedback">{errors?.payment_info?.cvv && errors?.payment_info?.cvv?.message}</div>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-12 form-group mb-3">
                            <label for="phonenumber" className="form-label">phone number</label>
                            <input type="text" className="form-control" {...register(`payment_info.phone_number`)} placeholder="phone number" id="phonenumber" required />
                        </div> */}
                        {
                            isUpdating ?
                                <div className="col-12 col-sm-12 col-md-12 form-group mb-0 text-end">
                                    <button type="submit" className="btn btn-primary">update</button>
                                </div>
                                : ''
                        }

                    </form>
                </div>

            </div>
        </>
    )
}

function Appointments(props) {

    const [appointment, setAppointments] = useState(false);
    const auth = useAuthUser()

    const [appointmentupdated, setAppointmentUpdated] = useState(false);



    const getAppointments = () => {
        axios.get('/client/get-appointments-new?salon_id=' + auth().salon_id)
            .then(res => {
                setAppointments(res.data.data);
            })
    }

    useEffect(() => {
        if (appointment) {
            // reset(appointment);
        } else {
            getAppointments();
        }
    }, [])

    useEffect(() => {
        getAppointments();
    }, [appointmentupdated])

    // ACCORDION
    const [clicked, setClicked] = useState("0");
    const [isActive, setIsActive] = useState(false);

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    const handleClick = event => {

        setIsActive(current => !current);
    };

    return (
        <>
            <div className="col-12 col-sm-12 col-md-4">
                <div className="myaccount-cont-card">
                    <h2 className="steps-box-title px-1 mb-2">appointments</h2>
                    <div className="px-2">
                        <div className="head-line">
                            <span>upcoming</span>
                        </div>

                        <div className='appts-upcoming'>
                            {/* <div className="card-apmt-list-main card-apmt-new">
                                <div className="card card-apmt-list">
                                    <div className={isActive ? 'card-header bg-primary' : 'card-header bg-primary'} onClick={handleClick}>
                                        thursday, january 21, 2023
                                        <strong className='apt-group-btn'>group</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-apmt-detail">service 01 <span>at</span> 8:00 am  <span className='ms-3'>$00.00</span></div>
                                        <div className="card-apmt-detail appt-new-detail"><span>location name</span>
                                            <div className='appt-new-icons'>
                                                <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-1.svg`} width="12" height="12" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-2.svg`} width="14" height="15" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-3.svg`} width="14" height="15" alt='icon' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={isActive ? 'card-body-show-apt accordion-body show' : 'card-body-show-apt accordion-body'}>
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <hr />
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="card-apmt-list-main card-apmt-new">
                                <div className="card card-apmt-list">
                                    <div className="card-header bg-primary">
                                        thursday, january 21, 2023
                                        <strong className='apt-group-btn'>group</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-apmt-detail">service 01 <span>at</span> 8:00 am  <span className='ms-3'>$00.00</span></div>
                                        <div className="card-apmt-detail appt-new-detail"><span>location name</span>
                                            <div className='appt-new-icons'>
                                                <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-1.svg`} width="12" height="12" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-2.svg`} width="14" height="15" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-3.svg`} width="14" height="15" alt='icon' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body-show-apt accordion-body">
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <hr />
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> */}


                            {
                                appointment ?
                                    appointment.current_appointments.map((app, i) =>
                                        <AppointmentRow upcoming={true} appstatus={{ appointmentupdated, setAppointmentUpdated }} details={app} accor={{ handleToggle, clicked }} />
                                    ) : ''
                            }
                        </div>


                        <div className="head-line mt-2">
                            <span>past</span>
                        </div>

                        <div className='aapt-past'>
                            {/* <div class="card-apmt-list-main card-apmt-new">
                                <div class="card card-apmt-list">
                                    <div class="card-header bg-light active">
                                        thursday, january 05, 2023
                                        <strong className='apt-group-btn'>group</strong>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-apmt-detail">service 01 <span>at</span> 8:00 am</div>
                                        <div className="card-apmt-detail appt-new-detail"><span>location name</span>
                                            <div className='appt-new-icons'>
                                                <div className='appt-new-icon-item'>
                                                    <img src="/images/calendar-1.svg" width="12" height="12" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src="/images/calendar-2.svg" width="14" height="15" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src="/images/calendar-3.svg" width="14" height="15" alt='icon' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body-show-apt accordion-body show'>
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <hr />
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-apmt-list-main card-apmt-new">
                                <div class="card card-apmt-list">
                                    <div class="card-header bg-light">
                                        thursday, january 05, 2023
                                        <strong className='apt-group-btn'>group</strong>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-apmt-detail">service 01 <span>at</span> 8:00 am</div>
                                        <div className="card-apmt-detail appt-new-detail"><span>location name</span>
                                            <div className='appt-new-icons'>
                                                <div className='appt-new-icon-item'>
                                                    <img src="/images/calendar-1.svg" width="12" height="12" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src="/images/calendar-2.svg" width="14" height="15" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item'>
                                                    <img src="/images/calendar-3.svg" width="14" height="15" alt='icon' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body-show-apt accordion-body'>
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <hr />
                                            <h2>guest 1 </h2>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                            <div class="form-check mt-1 form-check-reverse form-check-position grey-check">
                                                <input tabindex="0" type="checkbox" name="feature1" class="form-check-input" id="featurelist3" />
                                                <label tabindex="0" aria-label="click to remember me" class="form-check-label" for="featurelist3">service 01 at 8:00 am <span>$00.00</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {
                                appointment ?
                                    appointment.past_appointments.map((app, i) =>
                                        <AppointmentRow upcoming={false} appstatus={{ appointmentupdated, setAppointmentUpdated }} details={app} accor={{ handleToggle, clicked }} />
                                    ) : ''
                            }
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}

function AppointmentRow(props) {

    const [current, setCurrent] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const auth = useAuthUser()

    useEffect(() => {
        // console.log(props.details)
        setValue('service', '');
    }, [])


    const { register, handleSubmit, setValue, setError, watch, reset, getValues, formState: { errors } } = useForm();

    const watchservice = watch('service')

    useEffect(() => {
        // console.log(watchservice)

    }, [watchservice])

    const [showCancel, setShowCancel] = useState(false);
    const openModal = (app) => {
        if (!watchservice.length > 0) {
            toast.error('Please select at least one service', {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: 'serviceselection'
            })
            return;
        }
        // console.log(app);
        setShowCancel(true);
        setCurrent(app)
        // console.log(current);
    };

    const closeModal = () => {
        setShowCancel(false);
    };

    const cancel = (data) => {

        props.appstatus.setAppointmentUpdated(false);


        const modifiedmember = current.members.map((appt) => {

            const selectedservicesid = appt.selected_services.map((service) => {
                return service.service_id;
            })
            const newmember = {};
            newmember.personal_info = appt.personal_info

            const services = [];
            for (let index = 0; index < watchservice.length; index++) {
                const element = JSON.parse(watchservice[index]);
                if (selectedservicesid.includes(element.service_id)) {
                    services.push(element);
                }
            }

            newmember.selected_services = services;

            return newmember;
        })

        // console.log(modifiedmember);

        if (current) {
            let appt = {
                "salon_id": current.selected_salon_id,
                "status_type": "cancel",
                "appointment_info": {
                    "appointment_group_id": current.appointment_group_id,
                    "selected_salon_id": current.selected_salon_id,
                    "booking_for": current.booking_for,
                    "room_type": current.room_type,
                    "location_name": current.location_name,
                    "date": current.date,
                    "members": modifiedmember
                }
            }


            axios.post('client/update-appointment-status', appt)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status) {
                        toast.success('Appointment cancelled successfully.', {
                            position: toast.POSITION.BOTTOM_CENTER,
                            toastId: 'appointmentcanceled'
                        })
                        const elements = document.getElementsByClassName("overlaymenu");

                        while (elements.length > 0) elements[0].remove();

                        closeModal();
                        props.appstatus.setAppointmentUpdated(true);
                    }

                })
                .catch((res) => {
                    // // console.log(res);

                    if (res.response.status == 400) {

                    }


                })
        }


    }


    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const currency = {
        style: 'currency',
        currency: 'USD',
    };


    const reschedule = () => {
        if (!watchservice.length > 0) {
            toast.error('Please select at least one service', {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: 'serviceselection'
            })
            return;
        }

        const serviceids = watchservice.map((service) => {
            return service.service_id;
        })

        console.log(serviceids);


    }




    return (
        <>
            {showCancel ? <Modal setShowModal={setShowCancel} content={<CancelModal app={watchservice} cancel={cancel} close={closeModal}

            />} /> : null}


            {
                props.details.appointment_group_id != 0 ?
                    <div className="card-apmt-list-main card-apmt-new" key={props.details.appointment_group_id}>
                        <div className="card card-apmt-list">
                            <div className={props.upcoming ? 'card-header bg-primary' : 'card-header bg-light'} onClick={() => props.accor.handleToggle(props.details.appointment_group_id)}>
                                {/* {console.log(new Date(props.details.date+" 00:00:00").toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))} */}
                                {new Date(props.details.date+" 00:00:00").toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}

                                <strong className='apt-group-btn'>{props.details.booking_for}</strong>
                            </div>
                            <div className="card-body">
                                {/* <div className="card-apmt-detail">{props.details.service_name} <span>at</span> {formatAMPM(new Date(props.details.date + ' ' + props.details.time))}</div> */}
                                <div className="card-apmt-detail appt-new-detail"><span>{props.details.location_name}</span>

                                    {
                                        props.upcoming ?
                                            <div className='appt-new-icons'>
                                                {/* <div className='appt-new-icon-item'>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-1.svg`} width="12" height="12" alt='icon' />
                                                </div>
                                                <div className='appt-new-icon-item' onClick={e => reschedule(props.details)}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-2.svg`} width="14" height="15" alt='icon' />
                                                </div> */}
                                                {/* <div className='appt-new-icon-item' onClick={e => openModal(props.details)}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/calendar-3.svg`} width="14" height="15" alt='icon' />
                                                </div> */}
                                            </div>

                                            : ''
                                    }
                                </div>


                                <div className={'card-body-show-apt accordion-body ' + (props.details.appointment_group_id == props.accor.clicked ? 'show' : '')}>

                                    {
                                        props.details.members && props.details.members.length > 0 ?
                                            props.details.members.map((guest) =>

                                                <>
                                                    <h2>{guest.personal_info.first_name}{' '}{guest.personal_info.last_name}</h2>

                                                    {
                                                        guest.selected_services && guest.selected_services.length > 0 ?
                                                            guest.selected_services.map((service) =>
                                                                <div class="form-check mt-1 form-check-reverse form-check-position">
                                                                    {
                                                                        props.upcoming ?
                                                                            // <input tabindex="0" type="checkbox" {...register('service')} value={JSON.stringify(service)} class="form-check-input" id={service.service_id} />
                                                                            ""
                                                                            : ''
                                                                    }
                                                                    <label tabindex="0" aria-label="" class="form-check-label" for={service.service_id}>{service.service_name} at {formatAMPM(new Date(props.details.date + ' ' + service.time))} <span>${service.service_price.toLocaleString('en-US', currency)}</span></label>
                                                                </div>
                                                            )

                                                            : ''
                                                    }
                                                    <hr />
                                                </>
                                            )
                                            : ''
                                    }


                                </div>
                            </div>
                        </div>

                    </div >
                    :
                    ''
            }


        </>
    )
}

function CancelModal(props) {


    const datetime = () => {

        return props.app.date + ' ' + props.app.time;

    }

    return (
        <>
            <div className="modal" id="cancelappt" tabindex="-1" aria-labelledby="cancelapptModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '291px' }}>
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h2 className="modal-title" id="cancelapptModalLabel">cancel your appointment?</h2>
                            <button type="button" onClick={e => props.close()} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={`${process.env.PUBLIC_URL}/images/close_modal.svg`} width="11" height="11" alt="close icon" />
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="max-226 w-100 m-auto">
                                <p className="para text-center">Are you sure you want to cancel your appointment for {datetime()}?</p>
                            </div>
                            <div className="form-group mt-3 mb-0">
                                <button type='submit' onClick={e => props.close()} className="btn btn-primary text-center w-100">no, keep my appointment</button>
                            </div>
                            <div className="form-group mt-2 mb-0">
                                <button type="button" onClick={e => props.cancel()} className="btn btn-danger text-center w-100">yes, cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Account;