import React, { useEffect, useRef } from "react";
import ReactDom from "react-dom";
export const Modal = ({ setShowModal, content }) => {
  // close the modal when clicking outside the modal.
  // const modalRef = useRef();

  // useEffect(() => {
  //   var d = document.getElementById("portal");

  //   d.classList.add("bluered-background");
  // }, [])

  const closeModal = (e) => {
    // if (e.target === modalRef.current) {
    // setShowModal(false);
    // }
    var d = document.getElementById("portal");
    d.classList.remove("bluered-background");
  };

  //render the modal JSX in the portal div.
  return ReactDom.createPortal(
    <div className="container"
      // ref={modalRef} 
      onClick={closeModal}>
      <div className="modal">
        {content}
        {/* <button className="btn-close" onClick={() => closeModal}>X</button> */}
      </div>
    </div>,
    document.getElementById("portal")
  );
};