import React, { Component, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useSignIn, withSignIn } from 'react-auth-kit'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';


function Register() {

    const signIn = useSignIn()
    const { register, handleSubmit, watch, setError, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated()


    useEffect(() => {
        if (isAuthenticated()) {
            navigate(process.env.PUBLIC_URL + '/dashboard');
        }
    });

    useEffect(() => {
        // console.log(errors);
    }, [errors])

    // const watchpphone = watch('')


    async function onSubmit(formData) {
        // e.preventDefault()
        // if (formData.phone.length < 10) {
        //     setError('password', {
        //         type: "server",
        //         message: serrors.password[0],
        //     });
        // }
        // console.log(formData.phone);

        if (formData.phone.length < 10) {
            setError('phone', {
                type: "server",
                message: 'min 10 digits required',
            });
            // setCheckoutSubmitted(false);
            return
        }

        formData.salon_id = process.env.REACT_APP_SALOON_ID

        await axios.post(process.env.REACT_APP_API_HOST_URL + `api/web/client/register`, formData)
            .then((res) => {


                if (res.status === 200) {
                    if (signIn(
                        {
                            token: res.data.token,
                            expiresIn: res.data.expiresIn,
                            tokenType: "Bearer",
                            authState: res.data.authState,
                            saloon: res.data.authState.salon_id
                            // refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
                            // refreshTokenExpireIn: res.data.refreshTokenExpireIn     // Only if you are using refreshToken feature
                        }
                    )) { // Only if you are using refreshToken feature
                        // Redirect or do-something
                        Cookies.set('logged_in_salon_id', res.data.authState.salon_id);
                        Cookies.set('location_slug', res.data.location_slug);

                        if (process.env.REACT_APP_AUTH_TYPE == 'cookie') {
                            if (Cookies.get(process.env.REACT_APP_AUTH_NAME)) {
                                axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN_TYPE + ' ' + Cookies.get(process.env.REACT_APP_AUTH_NAME);
                                // // console.log(axiosinstance.defaults.headers.common);
                            }
                        }
                        navigate(process.env.PUBLIC_URL + '/dashboard');
                    } else {
                        //Throw error
                        // toast.error("Error Notification !", {
                        //     position: toast.POSITION.TOP_LEFT
                        //   });

                    }
                }

            })
            .catch((error) => {
                // // // console.log(r);
                // toast.error(error.response.data.message, {
                //     position: toast.POSITION.BOTTOM_CENTER
                // });

                // TODO write server side validation errors

                if (error.response.status == 422) {
                    const serrors = error.response.data.errors;
                    // // console.log(serrors);
                    if (serrors.first_name) {
                        setError('first_name', {
                            type: "server",
                            message: serrors.first_name[0],
                        });
                    }

                    if (serrors.last_name) {
                        setError('last_name', {
                            type: "server",
                            message: serrors.last_name[0],
                        });
                    }

                    if (serrors.email) {
                        setError('email', {
                            type: "server",
                            message: serrors.email[0],
                        });
                    }
                    if (serrors.password) {
                        setError('password', {
                            type: "server",
                            message: serrors.password[0],
                        });
                    }
                }
            });
    }

    const watchphone = watch('phone');

    useEffect(() => {
        if (watchphone) {

            setValue('phone', watchphone.replace(/[^0-9]/ig, '').substr(0, 10))
        }
    }, [watchphone])


    return (
        <>
            <div className="login-bg">
                <div className="signin-cont">
                    <div className="signin-cont-in">
                        <div className="signin-cont-in-lft">
                            <div className="signin-cont-in-lft-in">
                                <img src="images/logo.svg" width="183.12" height="86" alt="skoah Logo" />
                                <div className="powered-by">
                                    <img src="images/powered-by.svg" width="129.37" height="29.66" alt="powered by saloncloudsplus img" />
                                </div>
                            </div>
                        </div>
                        <div className="signin-cont-in-rgt">
                            <div className="signin-cont-in-rgt-in">
                                <h1>welcome</h1>
                                <h2>already have an account? <Link to={`${process.env.PUBLIC_URL}/login`} aria-label="click to log-in" role="link"> log in</Link></h2>
                                <form className="m-0 needs-validation" novalidate onSubmit={handleSubmit(onSubmit)}>
                                    <input type="hidden" {...register(`state`)} value="newyork" />
                                    <input type={'hidden'} {...register('salon_id')} value={process.env.REACT_APP_SALOON_ID} />

                                    <div className={'form-group mb-1 ' + (errors.first_name ? 'invalid-error' : '')}>
                                        <label htmlFor="first-name" className="form-label">first name</label>
                                        <input type="text" {...register("first_name", { required: true })} className="form-control" placeholder="first name" id="first-name" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.first_name && errors.first_name.message}</div>

                                    </div>
                                    <div className={'form-group mb-1 ' + (errors.last_name ? 'invalid-error' : '')}>
                                        <label htmlFor="last-name" className="form-label">last name</label>
                                        <input type="text" {...register("last_name", { required: true })} className="form-control" placeholder="last name" id="last-name" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.last_name && errors.last_name.message}</div>

                                    </div>
                                    <div className={'form-group mb-1 ' + (errors.phone ? 'invalid-error' : '')}>
                                        <label htmlFor="phone" className="form-label">phone number</label>
                                        <input type="text" {...register("phone", { required: true })} className="form-control" placeholder="phone" id="phone" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.phone && errors.phone.message}</div>

                                    </div>
                                    <div className={'form-group mb-1 ' + (errors.email ? 'invalid-error' : '')}>
                                        <label htmlFor="email1" className="form-label">email</label>
                                        <input type="text" {...register("email", { required: true })} className="form-control" placeholder="email" id="email1" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.email && errors.email.message}</div>

                                    </div>

                                    <div className={'form-group mb-1 ' + (errors.password ? 'invalid-error' : '')}>
                                        <label htmlFor="password1" className="form-label">password</label>
                                        <input type="password" {...register("password", { required: true })} className="form-control" placeholder="password" id="password1" required />
                                        <div class="invalid-feedback fw-normal position-static">{errors.password && errors.password.message}</div>

                                    </div>
                                    <div className="form-group my-2">
                                        <button type="submit" className="btn btn-primary">sign up</button>
                                        {/* <a href="dashboard.html" className="btn btn-primary" aria-label="click to login">sign up</a> */}
                                    </div>
                                    <div className="form-check mt-3">
                                        <input tabIndex="-1" type="checkbox" {...register("remember_token")} className="form-check-input" id="RememberMe" />
                                        <label tabIndex="0" aria-label="click to remember me" className="form-check-label" htmlFor="RememberMe">remember me</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-footer">
                    <div className="signin-footer-lft">© skoah 2023 | designed and developed by webappclouds</div>
                    <div className="signin-footer-rgt"><a href="">terms & conditions</a> <a href="">privacy policy</a></div>
                </div>
            </div>
        </>
    );
}

export default Register;