import axios from 'axios';
import { useStateMachine } from 'little-state-machine';
import React, { Component, useEffect, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import axiosinstance from '../../config/axiosinstance';
import updateAction from '../../lib/updateAction';


function BookAppointment() {
    const { actions, state } = useStateMachine({ updateAction });
    // const [currentstep, setCurrentStep] = useState(0);
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();

    const isReschedule = searchParams.get('reschedule');

    // const currentstep = location.pathname.split("/")[3];

    const currentstep = location.pathname.split("/")[location.pathname.split("/").length-1] ? location.pathname.split("/")[location.pathname.split("/").length-1] : location.pathname.split("/")[location.pathname.split("/").length-2] ;

    // console.log(currentstep);

    useEffect(() => {
        // console.log(state);
        // setCurrentStep(state.step);
    }, []);

    const isLocationSelected = () => {

        if ((
            currentstep == 'location'
            || currentstep == 'service'
            || currentstep == 'date-time'
            || currentstep == 'confirm'
            || currentstep == 'forms'
            || currentstep == 'checkout'
        )) {
            if (state.hasOwnProperty('location')) {
                return ' completed active';
            }
            else {
                return ' active';
            }
        }

    }

    const isserviceselected = () => {
        if ((
            currentstep == 'service'
            || currentstep == 'date-time'
            || currentstep == 'confirm'
            || currentstep == 'forms'
            || currentstep == 'checkout'
        )) {
            if (state.bookingfor == 'myself' && state.hasOwnProperty('service') && state.service != '') {
                // return true;
                return ' completed active';
            }

            if (state.bookingfor == 'someone' && state.hasOwnProperty('guest') && state.guest != '' && state.guest.hasOwnProperty('services')) {
                // return true;
                return ' completed active';
            }

            if (state.bookingfor == 'group' && state.hasOwnProperty('group') && state.group != '') {
                state.group.map((group) => {
                    // return group.hasOwnProperty('services');
                    return group.hasOwnProperty('services') ? ' completed active' : '';
                })
            }
            return 'active'

        }
        return '';
    }

    const istimeslotselected = () => {
        if ((
            currentstep == 'date-time'
            || currentstep == 'confirm'
            || currentstep == 'forms'
            || currentstep == 'checkout'
        )) {
            if (state.bookingfor == 'myself' && state.hasOwnProperty('timeslot') && state.timeslot != '' && state.timeslot != null) {
                return ' completed active';
            }

            if (state.bookingfor == 'someone' && state.hasOwnProperty('guest') && state.guest != '' && state.guest.hasOwnProperty('timeslot')) {
                return ' completed active';
            }

            if (state.bookingfor == 'group' && state.hasOwnProperty('group') && state.group != '') {
                state.group.map((group) => {
                    return group.hasOwnProperty('timeslot') ? ' completed active' : '';
                })
            }
            return 'active'
        }

        return false;
    }

    const isConfirmed = () => {
        if ((

            currentstep == 'confirm'
            || currentstep == 'forms'
            || currentstep == 'checkout'
        )) {
            if (state.hasOwnProperty('confirmed')) {
                return ' completed active';
            }
            else {
                return ' active';
            }
        }
    }

    const isForms = () => {
        if ((

            currentstep == 'forms'
            || currentstep == 'checkout'
        )) {
            if (state.hasOwnProperty('formconfirmed')) {
                return ' completed active';
            }
            else {
                return ' active';
            }
        }
    }

    useEffect(() => {
        // // console.log(isserviceselected())
    }, [isserviceselected()])




    return (
        <>
            <div className="dashboard-header">
                <div className="dashboard-header-lft">
                    <h1> {isReschedule ? 'reschedule' : 'book'} an appointment</h1>
                </div>
                {
                    isReschedule ? '' :
                        <div className="dashboard-header-rgt">

                            <ul>
                                <li className={isLocationSelected()} data-step="1"><span>1</span><strong>location</strong></li>
                                <li className={isserviceselected()}
                                    data-step="2"><span>2</span><strong>service</strong></li>
                                <li className={
                                    istimeslotselected()}
                                    data-step="3"><span>3</span><strong>date and time</strong></li>
                                <li className={
                                    isConfirmed()
                                } data-step="4"><span>4</span><strong>confirm</strong></li>
                                <li className={
                                    isForms()
                                } data-step="5"><span>5</span><strong>forms</strong></li>
                                <li className={currentstep == 'checkout' ? 'active' : ''} data-step="6"><span>6</span><strong>checkout</strong></li>
                            </ul>
                        </div>
                }

            </div >
            <div className="dashbaord-admin-main-content">
                <div
                    className="dashbaord-admin-main-content-card card card-body bg-transparent p-0 dashbaord-charts-page">
                    <div className="dashbaord-admin-main-content-card-scroll hide-scrollbar">
                        <div className="bk-ap-steps-gird">
                            <ul>
                                <Outlet />

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookAppointment;