import logo from './logo.svg';
import './App.css';
import { AuthProvider, RequireAuth } from 'react-auth-kit'
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import Login from './Auth/Login';
import Register from './Auth/Register';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './dashboard/Dashboard';
import BookAppointment from './dashboard/book-appointment/BookAppointment';
import Account from './dashboard/account/Account';
import Location from './dashboard/book-appointment/Location';
import Service from './dashboard/book-appointment/Service';
import Datetime from './dashboard/book-appointment/DateTime';
import Confirm from './dashboard/book-appointment/Confirm';
import Forms from './dashboard/book-appointment/Forms';
import Checkout from './dashboard/book-appointment/Checkout';
import { StateMachineProvider, createStore } from "little-state-machine";
import ForgetPassword from './Auth/ForgetPassword';
import ResetPassword from './Auth/ResetPassword';
import GiftCard from './dashboard/giftcard';
import Info from './dashboard/giftcard/Info';
import Payment from './dashboard/giftcard/Payment';
import GiftcardDashboard from './dashboard/giftcard/GiftcardDashboard';


createStore({});

function App() {
    return (
        <>

            {/* // TODO IMPLEMENT REFRESH TOKEN  */}
            <StateMachineProvider>
                <AuthProvider authType={process.env.REACT_APP_AUTH_TYPE}
                    authName={process.env.REACT_APP_AUTH_NAME}
                    cookieDomain={window.location.hostname}
                    cookieSecure={window.location.protocol === "https:"}>


                    <div className="wrapper">
                        <Routes basename={'skoahobreact'}>
                        <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
                            <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
                            <Route path={`${process.env.PUBLIC_URL}/register`} element={<Register />} />
                            <Route path={`${process.env.PUBLIC_URL}/forget-password`} element={<ForgetPassword />} />

                            {/* <Route path='giftcard/*' element={<GiftCard />} >
                                <Route index element={<Navigate to="info" replace />} />
                                <Route path='info' element={<Info />} />
                                <Route path='payment' element={<Payment />} />
                            </Route> */}
                            {/* <Route path='register' element={<Register />} /> */}

                            <Route path='reset' element={<ResetPassword />} />

                            <Route path={`${process.env.PUBLIC_URL}/dashboard/*`} element={
                                <RequireAuth loginPath={`${process.env.PUBLIC_URL}/login`}>
                                    <Routes>
                                        <Route path='*' element={<Dashboard />} >
                                            <Route index element={<Navigate to="book-appointment" replace />} />
                                            <Route path='book-appointment/*' element={<BookAppointment />} >
                                                <Route index element={<Navigate to="location" replace />} />
                                                <Route path='location' element={<Location />} />
                                                <Route path='service/:client?' element={<Service />} />
                                                <Route path='date-time/:client?' element={<Datetime />} />
                                                <Route path='confirm' element={<Confirm />} />
                                                <Route path='forms' element={<Forms />} />
                                                <Route path='checkout' element={<Checkout />} />

                                            </Route>

                                            <Route path='reschedule/*' element={<BookAppointment />} >
                                                <Route index element={<Navigate to="location" replace />} />
                                                <Route path='location' element={<Location />} />
                                                <Route path='service/:client?' element={<Service />} />
                                                <Route path='date-time/:client?' element={<Datetime />} />
                                                <Route path='confirm' element={<Confirm />} />
                                                <Route path='forms' element={<Forms />} />
                                                <Route path='checkout' element={<Checkout />} />

                                            </Route>

                                            <Route path='account' element={<Account />} />
                                            <Route path='gift-card' element={<GiftcardDashboard />} />
                                        </Route>
                                    </Routes>
                                </RequireAuth>
                            } />
                        </Routes>
                    </div>
                </AuthProvider>
            </StateMachineProvider>
        </>

    );
}

export default App;


function Home(params) {
    return (
        <>
        <Link to={`${process.env.PUBLIC_URL}/login`}>Login</Link>
        </>
    )
}