import React, { Component, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { EmptyCheckout } from './Checkout';
import { EmptyConfirm } from './Confirm';
import updateAction from '../../lib/updateAction';

import { EmptyForms } from './Forms';
import { EmptyLocation } from './Location';
import { EmptyService } from './Service';
import '@natscale/react-calendar/dist/main.css';
import { Calendar } from '@natscale/react-calendar';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import axios from 'axios';
import { useStateMachine } from 'little-state-machine';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';
import { useAuthUser } from 'react-auth-kit';

export const ConnectForm = ({ children }) => {
    const methods = useFormContext();

    return children({ ...methods });
};

function Datetime() {
    const [availableSlots, setAvailableSlots] = React.useState(false)
    const methods = useForm();
    const [selectedDateList, setSelectedDateList] = useState();
    const [session, setSession] = useState(false);
    const [program, setprogram] = useState(false);

    const { client } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const isReschedule = searchParams.get('reschedule');

    const [location, setLocation] = useState(false);
    const navigate = useNavigate();
    const { actions, state } = useStateMachine({ updateAction });

    const [activeClientForServiceSelection, setActiveClientForServiceSelection] = useState(false);


    const [clicked, setClicked] = useState("0");

    const handleToggle = (index, date) => {

        getAvailableSlots(date);

        methods.setValue('date', formatDateDMY(date));

        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    function formatDateDMY(date) {
        var d = new Date(date),
        
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            console.log(d)

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    const back = () => {

        if (isReschedule) {

            navigate(process.env.PUBLIC_URL + '/dashboard/account')
            return;

        }

        state.timeslot = null;
        // state.date = null;
        actions.updateAction(state);

        if (state.bookingfor == 'group') {

            for (var i = 0, iLen = state.group.length; i < iLen; i++) {

                if (state.group[i].client_id == client) {
                    // // console.log(i)
                    if (typeof state.group[i - 1] != 'undefined') {
                        setActiveClientForServiceSelection(state.group[i - 1]);
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time/' + state.group[i - 1].client_id)
                    } else {
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + state.group[state.group.length - 1].client_id)
                    }

                };
            }

        }

        if (state.bookingfor == 'someone') {
            // setActiveClientForServiceSelection(state.guest);
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service/' + state.guest.client_id)

        }

        if (state.bookingfor == 'myself') {
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/service');

        }

    };

    const next = (data) => {

        if (!value) {
            toast.error('Please choose date!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        if (!data.timeslot) {
            toast.error('Please select time slot!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }


        if (isReschedule) {

            actions.updateAction({ 'appt': appointment, isReschedule: true });
            actions.updateAction(data);
            actions.updateAction({ bookingfor: appointment.booking_for });
            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/confirm');


            return;
        }

        // // console.log(data)



        data['room'] = 1;

        if (data.bookingfor == 'group') {


            data.group = state.group.filter(function (obj) {

                if (obj.client_unique_id == activeClientForServiceSelection.client_unique_id) {
                    obj.timeslot = data.timeslot;
                    return obj;
                } else {
                    return obj;
                }
            });

            data.timeslot = null;
            data['room'] = 1;
            actions.updateAction(data);


            var c = 0;


            for (var i = 0; i < state.group.length; i++) {
                if (state.group[i].hasOwnProperty('timeslot') && state.group[i]['client_id'] === client) {
                    // // console.log(typeof state.group[i + 1]);
                    if (typeof state.group[i + 1] !== "undefined") {
                        setActiveClientForServiceSelection(state.group[i + 1]);
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time/' + state.group[i + 1].client_id)
                    } else {
                        navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/confirm');
                    }

                }
            }

        }

        if (data.bookingfor == 'someone') {

            data.guest.timeslot = data.timeslot;

            data.timeslot = null;

            actions.updateAction(data);

            if (state.guest.hasOwnProperty("timeslot")) {
                navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/confirm');
            }

        }

        if (data.bookingfor == 'myself') {
            if (data.timeslot == '') {
                toast.error('Please choose timeslot!',
                    { position: toast.POSITION.BOTTOM_CENTER }
                );
                return;
            }

            actions.updateAction(data);

            navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/confirm');
        }
        // actions.updateAction(data);
        // navigate(process.env.PUBLIC_URL+'/dashboard/book-appointment/confirm');
    };
    const [value, setValues] = useState(splitedDate(new Date(), 6));
    // const [value, setValues] = useState([new Date(2022, 6, 1), new Date(2022, 6, 6)]);
    const [week, setWeek] = useState(false);

    useEffect(() => {
        console.log(week)
    }, [week])

    const onChange = useCallback(
        (val) => {
            // console.log('date from onclick', val);
            setWeek(GetDates(val[0], 6))
            setValues(val);

            // methods.setValue('date', val)
            // // console.log('date from input' , getValues('date'))

        },
        [methods.setValue],
    );

    const [showModal, setShowModal] = useState(false);

    const [rooms, setRooms] = useState(false);


    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const watchdaytime = methods.watch('daytime');

    useEffect(() => {

        if (session) {
            getAvailableSlots(value);
        }
    }, [value, session]);

    useEffect(() => {

        if (activeClientForServiceSelection) {
            methods.setValue('timeslot', activeClientForServiceSelection.timeslot)

        }

    }, [activeClientForServiceSelection])

    var isDate = function (date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }


    useEffect(() => {
        // console.log(value);
        if (value && Array.isArray(value)) {
            setWeek(GetDates(value[0], 6));
            methods.setValue('date', formatDateDMY(value[0]));


        } else {
            methods.setValue('date', formatDateDMY(value));

        }

        // console.log(typeof value)
        // if (typeof value == 'object') {
        //     setWeek(GetDates(value, 7));
        // }

    }, [value])

    function GetDates(startDate, daysToAdd) {
        var aryDates = [];
        startDate = new Date(startDate);
        // startDate.toLocaleString('en-US', { timeZone: 'America/New_York' })

        for (var i = 0; i <= daysToAdd; i++) {
            var currentDate = new Date();
            // currentDate = currentDate.toLocaleString('en-US', { timeZone: 'America/New_York' })

            var day = 60 * 60 * 24 * 1000 * i;
            // // console.log(day);

            var endDate = new Date(startDate.getTime() + day);
            // currentDate.setDate(endDate.getTime() );
            console.log(new Date(endDate.toLocaleString('en-US', { timeZone: 'America/New_York' })));
            aryDates.push(new Date(endDate.toLocaleString('en-US', { timeZone: 'America/New_York' })));
        }

        return aryDates;

    }


    function splitedDate(startDate, daysToAdd) {

        var dates = GetDates(startDate, daysToAdd);
        return [dates[0], dates[6]];
    }

    const weekDaysLabel = {
        0: 'SUN',
        1: 'MON',
        2: 'TUE',
        3: 'WED',
        4: 'THU',
        5: 'FRI',
        6: 'SAT',
    };



    useEffect(() => {
        methods.setValue('daytime', 'morning_slot')

        if (isReschedule) {

            getAppointment()

            console.log(value);
            if (value) {
                methods.setValue('date', formatDateDMY(value[0]));
            }

        } else {
            methods.reset(state);

            if (value) {
                methods.setValue('date', formatDateDMY(value[0]));

            }

            if (!state.location) {
                navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/location')

            } else if (!state.bookingfor) {

            } else {

                if (isDate(state.date)) {
                    setValues(splitedDate(new Date(state.date), 6));
                }

                if (state.bookingfor == 'group') {
                    setSession(state.group.map((client, i) => {
                        if (typeof client.services == 'string') {

                            return JSON.parse(client.services).session_type_id;

                        } else {

                            return (client.services.map((session2, i) => {
                                return JSON.parse(session2).session_type_id;
                            }))[0]
                        }
                    }))

                    setprogram(state.group.map((client, i) => {
                        if (typeof client.services == 'string') {

                            return JSON.parse(client.services).program_id;

                        } else {
                            return (client.services.map((session2, i) => {
                                return JSON.parse(session2).program_id;
                            }))[0]
                        }
                    }))
                }

                if (state.bookingfor == 'someone') {
                    var sessiontypeids = [];

                    sessiontypeids.push(state.guest.services.map((session2, i) => {
                        return JSON.parse(session2) != null ? JSON.parse(session2).session_type_id : 0;
                    }))

                    // // console.log(sessiontypeids)

                    setSession(sessiontypeids[0]);
                }

                if (state.bookingfor == 'myself') {
                    if (typeof state.service == 'string') {
                        // setSession(JSON.parse(state.service).map((value, i) => {
                        setSession([JSON.parse(state.service).session_type_id]);
                        // }))
                    } else {
                        setSession(state.service.map((value, i) => {
                            return JSON.parse(value).session_type_id;
                        }))
                    }
                }




                setLocation(JSON.parse(state.location));

                if (state.bookingfor == 'group') {
                    axios.get('/client/resources', {
                        params: {
                            start_date: Array.isArray(value) ? formatDateDMY(value[0]) : formatDateDMY(value),
                            end_date: Array.isArray(value) ? formatDateDMY(value[1]) : formatDateDMY(value),
                            program_id: JSON.stringify(program),
                            salon_id: state.salon_id,
                        }
                    })
                        .then(res => {

                            setRooms(res.data.data);

                        }).catch(res => {
                            setRooms(false);
                        })

                    openModal(true)
                }

                if (state.bookingfor == 'group' && !client && state.group.length > 0) {
                    setActiveClientForServiceSelection(state.group[0]);
                    // // console.log('re to tk')
                    navigate(process.env.PUBLIC_URL + '/dashboard/book-appointment/date-time/' + state.group[0].client_id);
                }

                if (client) {
                    setShowModal(false);

                    switch (state.bookingfor) {
                        case 'myself':

                            break;

                        case 'someone':
                            setActiveClientForServiceSelection(state.guest);
                            break;

                        case 'group':
                            setActiveClientForServiceSelection(
                                state.group.filter(x =>
                                    x.client_id == client
                                )[0]
                            );
                            // setActiveClientForServiceSelection(state.guest);
                            break;

                        default:
                            break;
                    }
                }
            }
        }
        // methods.setValue('daytime', 'all');



    }, []);


    const auth = useAuthUser()



    useEffect(() => {
        // // console.log(session)
    }, [session])

    const formatDate = (date) => {


        console.log(date);
        if (date instanceof Date) {
            console.log(formatDateDMY(date))
            return new Date(date)?.toJSON()?.split("T")[0];
        }

    }

    const getAvailableSlots = (value) => {

        var params = {};
        if (isReschedule) {



            params = {
                // params: {
                from_date: Array.isArray(value) ? formatDateDMY(value[0]) : formatDateDMY(value),
                to_date: Array.isArray(value) ? formatDateDMY(value[1]) : formatDateDMY(value),
                "logged_in_salon_id": auth().salon_id,
                "selected_salon_id": appointment.selected_salon_id,
                booking_for: appointment.booking_for,
                "room_type": "double",
                "time_of_day": watchdaytime
            }
        } else {
            params = {
                // params: {
                from_date: Array.isArray(value) ? formatDateDMY(value[0]) : formatDateDMY(value),
                to_date: Array.isArray(value) ? formatDateDMY(value[1]) : formatDateDMY(value),
                "logged_in_salon_id": auth().salon_id,
                "selected_salon_id": state.salon_id,
                booking_for: state.bookingfor,
                "room_type": "double",
                "time_of_day": watchdaytime
            }
        }


        const members = () => {
            if (state.bookingfor == 'myself' || isReschedule) {
                return {
                    "personal_info": {
                        "first_name": auth().first_name,
                        "last_name": auth().last_name,
                        "email": auth().email,
                        "phone": auth().phone,
                        "promo_code": state.promo_code ?? ''
                    },
                    selected_service_ids: isReschedule ? session[0] : session
                }
            }

            if (state.bookingfor == 'someone') {
                return {
                    "personal_info": {
                        "first_name": state.guest.first_name,
                        "last_name": state.guest.last_name,
                        "email": state.guest.email,
                        "phone": state.guest.phone,
                        "promo_code": state.guest.promo_code
                    },
                    selected_service_ids: isReschedule ? session[0] : session
                }
            }
        }

        // const add_memebers = () => {
        var memebrs = [];
        memebrs.push(members());
        params.members = memebrs;
        // }


        axios.post('/client/get-slots', params)
            .then(res => {
                setAvailableSlots(res.data.data);
                setSelectedDateList(availableSlots);


            }).catch(res => {
                setAvailableSlots(false);
                setSelectedDateList(false);
            })

    }

    const [appointment, setAppointments] = useState(false);

    const getAppointment = () => {
        axios.get('/client/get-appointments-new?salon_id=' + auth().salon_id)
            .then(res => {
                // setSession([res.data.appointment[0].service_id])
                // setAppointments(res.data.appointment[0]);

                const rescheduleappt = res.data.data.current_appointments.map((appt) => {
                    if (appt.appointment_group_id == isReschedule) {
                        return appt;
                    }
                })

                let rescheduleapptfiltered = rescheduleappt.filter(function (element) {
                    return element !== undefined;
                });

                setAppointments(rescheduleapptfiltered[0]);



            })
    }

    useEffect(() => {

        if (appointment && isReschedule) {
            const serviceids = appointment.members.map((members) => {
                return members.selected_services.map((service) => {
                    return service.service_id

                })
            })

            setSession(serviceids);
        }

    }, [appointment])

    useEffect(() => {

    }, [rooms])

    useEffect(() => {
        // // console.log(availableSlots);
        setClicked(0);
    }, [availableSlots]);

    useEffect(() => {
        // // console.log(watchdaytime);
    }, [watchdaytime]);

    const isDisabled = useCallback((date) => {
        var today = new Date();
        if (today >= date) {
            return true;
        }
    }, []);

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }


    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    }
    

    return (
        <>


            <EmptyLocation />

            <EmptyService />

            <li className="active">
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="13.65" height="15.16"
                            alt="date and time icon" />
                    </div>



                    <h2 className="steps-box-title">select a date and time</h2>


                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(next)}>

                            {showModal ? <Modal setShowModal={setShowModal} content={<Room
                                rooms={rooms}
                            />} /> : null}


                            {/* <input type={'hidden'} {...register('staff_id') } */}
                            <div className="bk-ap-steps-box-cont">


                                {state && state.bookingfor == 'group' && activeClientForServiceSelection ?
                                    <div className="form-group mb-3 ">
                                        <label className="form-label fw-normal">select a date & time for</label>
                                        <div className="checked-btns">
                                            <h4 className='steps-sub-title'>
                                                {activeClientForServiceSelection.first_name + ' ' +
                                                    activeClientForServiceSelection.last_name}
                                            </h4>
                                        </div>
                                    </div>
                                    : ''}

                                {state && state.bookingfor == 'someone' && activeClientForServiceSelection ?
                                    <div className="form-group mb-3 ">
                                        <label className="form-label fw-normal">select a date & time for</label>
                                        <div className="checked-btns">
                                            <h4 className='steps-sub-title'>
                                                {activeClientForServiceSelection.first_name + ' ' +
                                                    activeClientForServiceSelection.last_name}
                                            </h4>
                                        </div>
                                    </div>
                                    : ''}





                                <div className="form-group mb-2 pb-1">
                                    <label className="form-label fw-normal">select a date range</label>
                                    <div className="calendaer-view">
                                        <div className="cf-hidden visually-hidden">
                                            <input className="form-control" aria-label="from date" type="text" id="start-date" />
                                            <input className="form-control" aria-label="to date" type="text" id="end-date" />
                                        </div>
                                        {/* <div id="dp-skoah"></div> isRangeSelector  isDisabled={isDisabled}*/}
                                        <Calendar weekDaysLabel={weekDaysLabel} weekends={[]} fixedRange={6} isRangeSelector noPadRangeCell isDisabled={isDisabled} value={value} onChange={onChange} />
                                        <input type={'hidden'} {...methods.register(`date`)} />
                                    </div>
                                </div>

                                <div className="form-group mb-2 pb-1">
                                    <label className="form-label fw-normal">select a time of day</label>
                                    <div className="checked-btns">
                                        <input type="radio" {...methods.register(`daytime`)} value={'morning_slot'} className="btn-check" id="morning" autoComplete="off" />
                                        <label className="btn btn-primary me-2" htmlFor="morning">morning</label>

                                        <input type="radio" {...methods.register(`daytime`)} value={'afternoon_slot'} className="btn-check" id="afternoon" autoComplete="off" />
                                        <label className="btn btn-primary me-2" htmlFor="afternoon">afternoon</label>

                                        <input type="radio" {...methods.register(`daytime`)} value={'evening_slot'} className="btn-check" id="evening" autoComplete="off" />
                                        <label className="btn btn-primary me-2" htmlFor="evening">evening</label>
                                    </div>

                                </div>
                                <div className="form-group mb-2 pb-1" >
                                    <div className="card p-3">
                                        <label className="form-label fw-normal">select an appointment time</label>
                                        <div className="card-scroll-time">
                                            <div className="accordion card-grey p-0" id="accordionExample">


                                                {
                                                    availableSlots && week ?

                                                        week.map((date, i) =>
                                                            <div className="accordion-item" key={i}>
                                                                {console.log(new Date(availableSlots[0].date + ' 00:00:00'))}
                                                                {console.log(new Date(date).getDate(), (new Date(availableSlots[0].date + ' 00:00:00')))}

                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className={'accordion-button' + (new Date(availableSlots[0].date+ ' 00:00:00').getDate() !== new Date(date).getDate() ? " collapsed" : "")} onClick={() => handleToggle(new Date(date+ ' 00:00:00').getDate(), date)} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        {new Date(date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase()}
                                                                    </button>
                                                                    {/* <input type={'hidden'} value={12} {...methods.register('staffid')} /> */}
                                                                </h2>

                                                                <div id="collapseOne" className={'accordion-collapse collapse' + (new Date(availableSlots[0].date+ ' 00:00:00').getDate() === new Date(date).getDate() ? " show" : "")} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">
                                                                        <div className="checked-btns">
                                                                            {


                                                                                availableSlots[0].members &&
                                                                                    availableSlots[0].members.length > 0 && availableSlots[0].members[0].slots.length > 0 ?
                                                                                    availableSlots[0].members[0].slots.map((slot, j) => {
                                                                                        return <>

                                                                                            {/* {// console.log(availableSlots[0].date)} */}

                                                                                            {


                                                                                                watchdaytime == 'morning_slot' ?
                                                                                                    new Date(availableSlots[0].date + ' ' + slot.services[0].start_time).getHours() < 12 ?
                                                                                                        <>
                                                                                                            <input type="radio" className="btn-check" {...methods.register(`timeslot`)} value={JSON.stringify(slot)} id={'timeslot' + i + j} autoComplete="off" />
                                                                                                            <label className="btn btn-primary me-2 mb-1" for={'timeslot' + i + j}>{formatAMPM(new Date(availableSlots[0].date + ' ' + slot.services[0].start_time))}</label>
                                                                                                        </>
                                                                                                        : ''
                                                                                                    : ''
                                                                                            }
                                                                                            {
                                                                                                watchdaytime == 'evening_slot' ?
                                                                                                    new Date(availableSlots[0].date + ' ' + slot.services[0].start_time).getHours() >= 15 ?
                                                                                                        <>
                                                                                                            <input type="radio" className="btn-check" {...methods.register(`timeslot`)} value={JSON.stringify(slot)} id={'timeslot' + i + j} autoComplete="off" />
                                                                                                            <label className="btn btn-primary me-2 mb-1" for={'timeslot' + i + j}>{formatAMPM(new Date(availableSlots[0].date + ' ' + slot.services[0].start_time))}</label>
                                                                                                        </>
                                                                                                        : ''
                                                                                                    : ''
                                                                                            }

                                                                                            {
                                                                                                watchdaytime == 'afternoon_slot' ?
                                                                                                    new Date(availableSlots[0].date + ' ' + slot.services[0].start_time).getHours() >= 12 && new Date(availableSlots[0].date + ' ' + slot.services[0].start_time).getHours() < 15 ?
                                                                                                        <>
                                                                                                            <input type="radio" className="btn-check" {...methods.register(`timeslot`)} value={JSON.stringify(slot)} id={'timeslot' + i + j} autoComplete="off" />
                                                                                                            <label className="btn btn-primary me-2 mb-1" for={'timeslot' + i + j}>{formatAMPM(new Date(availableSlots[0].date + ' ' + slot.services[0].start_time))}</label>
                                                                                                        </>
                                                                                                        : ''
                                                                                                    : ''
                                                                                            }



                                                                                        </>
                                                                                    }

                                                                                    )
                                                                                    :
                                                                                    <div class="no-slots">there are no available slots</div>


                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        : ''
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="step-btns">


                                <button type="button" className="btn btn-light ms-2" onClick={(e) => back()} >back</button>

                                <button type="submit" className="btn btn-primary ms-2"  >next</button>


                            </div>
                        </form>
                    </FormProvider>
                </div>
            </li>

            <EmptyConfirm />

            <EmptyForms />

            <EmptyCheckout />
        </>
    );
}

export default Datetime;

export function EmptyDateTime() {

    return (
        <>
            <li >
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/date-and-time-icon.svg`} width="13.65" height="15.16"
                            alt="date and time icon" />
                    </div>
                    <h2 className="steps-box-title">select a date and time</h2>
                </div>
            </li>
        </>
    )
}

function Room(props) {

    useEffect(() => {
        // // console.log(props.rooms)
    }, [props.rooms])

    return (
        <>

            <ConnectForm>
                {

                    ({ register, watch }) =>
                        <div className="modal" id="roomSelectionModal" tabindex="-1" aria-labelledby="roomSelectionModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '295px' }}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2 className="modal-title" id="roomSelectionModalLabel">room selection</h2>
                                    </div>
                                    <div className="modal-body">

                                        <div className="max-226 w-100 m-auto mb-3">
                                            <p className="para text-center">would you like a double room or single rooms for you and your guest?</p>
                                        </div>
                                        {/* <!-- <div className="form-group mt-2 mb-0">
                                <a href="service.html" className="btn btn-primary text-center w-100">double room</a>
                            </div>
                            <div className="form-group mt-2 mb-0">
                                <a href="service.html" className="btn btn-light text-center w-100">single rooms</a>
                            </div> --> */}

                                        <div className="checked-btns text-center">

                                            {
                                                props.rooms ?
                                                    props.rooms.map((room, i) => {
                                                        return (
                                                            <>
                                                                <input type="radio" className="btn-check" {...register('room')} id={'room' + i} value={room.resource_id} autoComplete="off" />
                                                                <label className="btn btn-primary mb-2 w-100" for={'room' + i}>room {room.resource_name}</label>
                                                            </>
                                                        )

                                                    })

                                                    : ''
                                            }


                                            {/* <input type="radio" className="btn-check" name="room" id="room2" autoComplete="off" />
                                <label className="btn btn-primary mb-2 w-100" for="room2">single rooms</label> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </ConnectForm>
        </>
    )
}

function Slots(props) {
    return (
        <>

        </>
    )
}