import React, { Component, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmptyCheckout } from './Checkout';
import { EmptyConfirm } from './Confirm';
import { EmptyDateTime } from './DateTime';

import { EmptyLocation } from './Location';
import { EmptyService } from './Service';
import updateAction from '../../lib/updateAction';
import { useStateMachine } from 'little-state-machine';


function Forms() {


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { actions, state } = useStateMachine({ updateAction });


    const back = () => {
        
        navigate(process.env.PUBLIC_URL+'/dashboard/book-appointment/confirm');
    };

    const next = () => {
        actions.updateAction({ step: 5, formconfirmed: true });
        navigate(process.env.PUBLIC_URL+'/dashboard/book-appointment/checkout');
    };

    const submitForm = (data) => {
        data['form_url'] = searchParams.get("title") ?? null;
        actions.updateAction(data);

        navigate(process.env.PUBLIC_URL+'/dashboard/book-appointment/checkout');
    };

    const form = () => {

        switch (searchParams.get("title")) {

            case "newcustomer": return <NewCustomerForm submitForm={submitForm} state={state} />;
            case "peelwaiverform": return <PeelWaiverForm submitForm={submitForm} state={state} />;
            case "glowwaiverform": return <GlowWaiverForm submitForm={submitForm} state={state} />;
            //   case "four":  return <ComponentD />;

            default: return <Noform />
        }
    }

    useEffect(() => {

    }, [state])

    return (
        <>

            <EmptyLocation />

            <EmptyService />

            <EmptyDateTime />

            <EmptyConfirm />

            <li className="active">
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/forms-icon.svg`} width="14.34" height="15.92"
                            alt="forms icon" />
                    </div>

                    {form()}
                    <div className="step-btns">
                        <button type="button" className="btn btn-light ms-2" onClick={(e) => back()}>back</button>
                        {/* <a className="btn btn-light ms-2" href="">back</a>
                        <a className="btn btn-primary ms-2" href="">next</a> */}
                        <button type="button" className="btn btn-primary ms-2" form="hook-form" onClick={(e) => next()}>next</button>
                    </div>
                </div>
            </li>

            <EmptyCheckout />

        </>
    );
}

export default Forms;

export function EmptyForms() {

    return (
        <>
            <li >
                <div className="bk-ap-steps-box">
                    <div className="bk-ap-step-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/forms-icon.svg`} width="14.34" height="15.92"
                            alt="forms icon" />
                    </div>
                    <h2 className="steps-box-title">forms</h2>
                </div>
            </li>
        </>
    )
}

function Noform(params) {
    return (
        <>
            <h2 className="steps-box-title">forms</h2>

            <div className="bk-ap-steps-box-cont">
                <div className="card card-confirm card-form p-3 text-center">
                    <h3 className="form-title">good to go!</h3>
                    <p className="para m-0">there are no forms for you to fill out</p>

                </div>


            </div>
        </>
    )
}

function NewCustomerForm(props) {
    const { register, handleSubmit, setValue, watch, reset, getValues } = useForm();


    const next = (data) => {
        // console.log(data);
        props.submitForm(data);
    }

    useEffect(() => {
        reset(props.state);
    }, [])


    return (
        <>
            <h2 className="steps-box-title">new customer form</h2>
            <div className="bk-ap-steps-box-cont">
                <div className="card card-confirm card-form p-3">
                    <h3 className="form-title">welcome!</h3>
                    <p className="para">could you fill in your contact information, please?</p>
                    <form className="mt-1" id="hook-form" onSubmit={handleSubmit(next)}>
                        <input type="hidden" {...register('form_type')} value={'customer'} />
                        <div className="row g-2 max-480">
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="date" className="form-label">date</label>
                                <input type="date" {...register(`form.customer_date`)} className="form-control form-control-gray datepicker" placeholder="date" id="date" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="shop" className="form-label">skoah shop</label>
                                <select id="shop" {...register(`form.skoah_shop`)} className="form-control form-select form-control-gray selectpicker" title="Select">
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="first" className="form-label">name</label>
                                <input type="text" {...register(`form.first_name`)} className="form-control form-control-gray" placeholder="first" id="first" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label className="form-label">&nbsp;</label>
                                <input type="text" {...register(`form.last_name`)} className="form-control form-control-gray" placeholder="last" id="last" required aria-label="last" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="phone" className="form-label">phone</label>
                                <input type="number" {...register(`form.phone`)} className="form-control form-control-gray" placeholder="phone" id="phone" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="email" className="form-label">email</label>
                                <input type="email" {...register(`form.email`)} className="form-control form-control-gray" placeholder="phone" id="email" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                <label for="birthday" className="form-label">birthday</label>
                                <input type="date" {...register(`form.birth_date`)} className="form-control form-control-gray datepicker" placeholder="birthday" id="birthday" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                <label for="howdidyouhear" className="form-label">how did you hear about skoah?</label>
                                <input type="text" {...register(`form.how_you_hear_us`)} className="form-control form-control-gray" placeholder="how did you hear about skoah?" id="howdidyouhear" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                <label for="address1" className="form-label">address</label>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 mb-2">
                                        <input type="text" {...register(`form.AddressLine1`)} className="form-control form-control-gray" placeholder="address line 1" id="address1" required />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 mb-2">
                                        <input type="text" {...register(`form.AddressLine2`)} className="form-control form-control-gray" placeholder="address line 2" id="address2" required aria-label="address line 2" />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 mb-2">
                                        <input type="text" {...register(`form.city`)} className="form-control form-control-gray" placeholder="city" id="city" required aria-label="city" />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 mb-2">
                                        <input type="text" {...register(`form.state`)} className="form-control form-control-gray" placeholder="state / province / region" id="state" required aria-label="state / province / region" />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 mb-2">
                                        <input type="text" {...register(`form.zip_code`)} className="form-control form-control-gray" placeholder="postal / zip code" id="postal" required aria-label="postal / zip code" />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 mb-2">
                                        <select id="shop" {...register(`form.country`)} className="form-control form-select form-control-gray selectpicker">
                                            <option>country</option>
                                            <option>country</option>
                                            <option>country</option>
                                            <option>country</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                <label for="howdidyouhear" className="form-label">would you prefer to receive future appointment confirmation or transactional information (eg. receipts) by:</label>
                                <div className="mb-1 d-flex align-items-center flex-wrap">
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.appointment_confirmation_email`)} type="checkbox" value="1" className="form-check-input" id="emailchk" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="emailchk">email</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.appointment_confirmation_text`)} type="checkbox" value="1" className="form-check-input" id="textchk" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="textchk">text</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                <label for="howdidyouhear" className="form-label">we will never rent or sell your personal information to anyone. ever. you can be the first to know when we offer any treats and surprises. would you prefer to be contacted by</label>
                                <div className="mb-1 d-flex align-items-center flex-wrap">
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.prefer_email_me`)} type="checkbox" value="1" className="form-check-input" id="emailmechk" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="emailmechk">email me</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.prefer_text_me`)} type="checkbox" value="1" className="form-check-input" id="textmechk" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="textmechk">text me</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.prefer_do_not_contact`)} type="checkbox" value="1" className="form-check-input" id="contactmechk" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="contactmechk">do not contact me</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row max-590">
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-1">
                                <label for="howdidyouhear" className="form-label">is there anything we should know before your treatment begins?</label>
                                <div className="mb-1 d-flex align-items-center flex-wrap">
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1"  {...register(`form.treatment_allergies`)} type="checkbox" value="1" className="form-check-input" id="allergies" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="allergies">allergies</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.treatment_medication`)} type="checkbox" value="1" className="form-check-input" id="medication" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="medication">medication</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.treatment_injuries`)} type="checkbox" value="1" className="form-check-input" id="injuries" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="injuries">injuries</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.treatment_hiv_hepatits`)} type="checkbox" value="1" className="form-check-input" id="hivhepatits" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="hivhepatits">hiv/hepatits</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.treatment_diabetic`)} type="checkbox" value="1" className="form-check-input" id="diabetic" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="diabetic">diabetic</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1" {...register(`form.treatment_heart_condition`)} type="checkbox" value="1" className="form-check-input" id="heartcondition" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="heartcondition">heart condition</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input tabindex="-1"{...register(`form.treatment_blood_pressure`)} type="checkbox" value="1" n className="form-check-input" id="bloodpressure" />
                                        <label tabindex="0" aria-label="click to email" className="form-check-label" for="bloodpressure">blood pressure</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-1 d-inline-flex align-items-center">
                                        <input tabindex="-1" {...register(`form.treatment_other`)} type="checkbox" value="1" className="form-check-input me-2" id="othertreatment" aria-label="other treatment" />
                                        <input type="text" className="form-control form-control-gray" aria-label="Enter other treatment" placeholder="other" style={{ width: '226px' }} />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row max-480">
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-4">
                                <label for="birthday" className="form-label">can we have your autograph please?</label>
                                <div id="defaultSignature"></div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                                <h3 className="form-title">thank you.</h3>
                            </div>
                        </div>

                    </form>
                </div>


            </div>
        </>
    )
}

function PeelWaiverForm(props) {
    const { register, handleSubmit, setValue, watch, reset, getValues } = useForm();
    const next = (data) => {
        // console.log(data);
        props.submitForm(data);
    }

    useEffect(() => {
        reset(props.state);
    }, [])

    return (
        <>
            <h2 className="steps-box-title">peel and dermaplane waiver form</h2>
            <div className="bk-ap-steps-box-cont">
                <div className="card card-confirm card-form p-3">
                    <div className="peel-cont">
                        <p>Dermaplaning uses a blade to gently exfoliate the outer layer of dead skin cells and remove fine hair commonly known as “peach fuzz”. This procedure produces an immediately more radiant appearance. Following this treatment, makeup application is smoother and other skin products penetrate deeper making them more effective.</p>
                        <p>After a treatment skin may feel tight and warm as if exposed to the sun or wind, but this should resolve within 24 hours. Slight redness and swelling might appear with more intensive treatments.</p>
                        <p>I understand that the treatment may involve the risk of complication or injury and I freely assume those risks. Anytime the skin barrier is broken, there is a small risk of bacterial or viral infection. Possible side effects of the treatment area can include mild redness of the skin, irritation and dryness. Additionally, nicks to the skin can occur due to the sharp surgical blade. Patient will be notified and the area will be treated if necessary. The hair is expected to grow back blunt-ended. New hair will not appear darker or denser. However, I do understand that any hormonal imbalance that may be present within my anatomical system can alter normal hair growth pattern.</p>
                        <p>I understand that the sensation and penetration of the peel will be enhanced which may cause skin irritation, mild discomfort, and tenderness, lightening or darkening of the skin, infection, scarring, peeling, and activation of cold sores.</p>
                        <p>This glycolic peel may cause the following reactions: redness, dryness, photo sensitivity, tingling, pain, burning, itching, peeling, hives, rash, scarring and scabbing. Many people will not experience any of these reactions. This is not an exhaustive list. Other reactions may occur. In some cases, skin will react by producing excessive sebum temporarily. If adverse reactions occur, consult a physician.</p>
                        <p>I am aware I should not expose skin to sun without sunscreen. I should not have this treatment if I am using prescriptive topicals.</p>
                        <p>I should not have this treatment if I have used accutane in the past 6 months and retinol (of any kind) in the past 3 days.</p>
                        <p>I should not have this treatment if I am pregnant or breastfeeding. I should not have any facial waxing done 7 days before or after this treatment.</p>
                        <p>I am aware that these treatments are recommended no more than once every 2 months, and choosing to do so more frequently is at my own risk.</p>
                        <p>I am aware that my fresh, newly exposed skin will be delicate. It is important that I use a mild basic cleanser and keep the skin well moisturized particularly around the delicate eye area. I should use a full spectrum sunblock daily. Avoid the use of Retinol-A, Renova, alpha or beta hydroxyl acid products and all forms of scrubs for at least 24 hours after a treatment. Avoid swimming and tanning beds for at least one week.</p>
                        <p>I am aware that there is a small risk of infection anytime the skin barrier is compromised and as such I agree to seek medical attention and not hold skoah responsible if I suspect skin has been damaged or infected.</p>
                        <p>BY SIGNING BELOW, I ACKNOWLEDGE THAT I HAVE UNDERSTOOD THE ABOVE INFORMATION AND THEREBY CONSENT AND AGREE TO THE TREATMENT AND ITS ASSOCIATED RISKS. I HEREBY CONSENT TO RECEIVE A PEEL FACIAL.</p>
                        <p>PLEASE NOTE: skoah cannot perform this service if this form is incomplete.</p>
                    </div>
                    <form id="hook-form" onSubmit={handleSubmit(next)}>

                        <input type="hidden" {...register('form.form_type')} value={'peelwaiver'} />

                        <div className="row g-2 max-480">
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="date" className="form-label">date</label>
                                <input type="text" {...register('form.customer_date')} className="form-control form-control-gray datepicker" placeholder="date" id="date" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="shop" className="form-label">skoah shop</label>
                                <select id="shop" {...register('skoah_shop')} className="form-control form-select form-control-gray selectpicker" title="Select">
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="first" className="form-label">name</label>
                                <input type="text" {...register('form.customer_date')} className="form-control form-control-gray" placeholder="first" id="first" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label className="form-label">&nbsp;</label>
                                <input type="text" {...register('form.last_name')} className="form-control form-control-gray" placeholder="last" id="last" required aria-label="last" />
                            </div>
                        </div>
                        <div className="row max-480">
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                                <label for="birthday" className="form-label">can we have your autograph please?</label>
                                <div id="defaultSignature"></div>
                            </div>

                        </div>
                    </form>

                </div>


            </div>
        </>
    )
}

function GlowWaiverForm(props) {
    const { register, handleSubmit, setValue, watch, reset, getValues } = useForm();
    const next = (data) => {
        // console.log(data);
        props.submitForm(data);
    }

    useEffect(() => {
        reset(props.state);
    }, [])

    return (
        <>
            <h2 className="steps-box-title">glow and super glow facial waiver form</h2>
            <div className="bk-ap-steps-box-cont">
                <div className="card card-confirm card-form p-3">
                    <div className="peel-cont">
                        <p>I should not have this treatment if I have used accutane in the past 6 months and retinol (of any kind) in the past 3 days.</p>
                        <p>Dermaplaning:</p>
                        <p>Dermaplaning uses a blade to gently exfoliate the outer layer of dead skin cells and remove fine hair commonly known as “peach fuzz”. This procedure produces an immediately more radiant appearance. Following this treatment, makeup application is smoother and other skin products penetrate deeper making them more effective.
                        </p>
                        <p>After a treatment skin may feel tight and warm as if exposed to the sun or wind, but this should resolve within 24 hours. Slight redness and swelling might appear with more intensive treatments.</p>
                        <p>I understand that the treatment may involve the risk of complication or injury and I freely assume those risks. Anytime the skin barrier is broken, there is a small risk of bacterial or viral infection. Possible side effects of the treatment area can include mild redness of the skin, irritation and dryness. Additionally, nicks to the skin can occur due to the sharp surgical blade. Patient will be notified and the area will be treated if necessary. The hair is expected to grow back blunt-ended. New hair will not appear darker or denser. However, I do understand that any hormonal imbalance that may be present within my anatomical system can alter normal hair growth pattern.</p>
                        <p>I am aware I should not expose skin to sun without sunscreen. I should not have this treatment if I am using prescriptive topicals.</p>
                        <p>I am aware that these treatments are recommended no more than once every 2 months, and choosing to do so more frequently is at my own risk.</p>
                        <p>I am aware that my fresh, newly exposed skin will be delicate. It is important that I use a mild basic cleanser and keep the skin well moisturized particularly around the delicate eye area. I should use a full spectrum sunblock daily. Avoid the use of Retinol-A, Renova, alpha or beta hydroxyl acid products and all forms of scrubs for at least 24 hours after a treatment. Avoid swimming and tanning beds for at least one week.</p>
                        <p>I am aware that there is a small risk of infection anytime the skin barrier is compromised and as such I agree to seek medical attention and not hold skoah responsible if I suspect skin has been damaged or infected.</p>
                        <p>High Frequency:</p>
                        <p>Note: High frequency is NOT recommended for facial guests with the following conditions – pacemakers / braces, heart conditions, epilepsy, pregnancy, high blood pressure, inflamed or couperose skin. Facial guest must remove all jewelry prior to treatment.</p>
                        <p>The high frequency delivers low-level frequencies known as microcurrents (less than one millionth of an amp) that work in harmony with the body’s electrical system. High frequency provides the muscles with very small amounts of electricity causing them to flex and relax. This is a gentle noninvasive treatment that ‘re-educations’ the muscle.</p>
                        <p>I am aware that High Frequency treatments involve conducting mild electrical currents through the body, and that this brings some inherent risk.
                        </p>
                        <p>I am aware that reactions are rare, but may include nausea, dizziness, weakness, and possible skin reactions including redness and/or other irritations.
                        </p>
                        <p>I am aware that some facial guests report slight tingling sensations, flashing of the optic nerve, and/or a metallic taste in the mouth during the procedure.
                        </p>
                        <p>I am aware that while the goal of this treatment is to improve the vitality of the skin, no specific guarantees of the result can or have been made.
                        </p>
                        <p>I have disclosed all and any metal piercings that I have.
                        </p>
                        <p>BY SIGNING BELOW, I ACKNOWLEDGE THAT I HAVE UNDERSTOOD THE ABOVE INFORMATION AND THEREBY CONSENT AND AGREE TO THE TREATMENT AND ITS ASSOCIATED RISKS. I HEREBY CONSENT TO RECEIVE A GLOW/SUPER GLOW FACIAL.
                        </p>
                        <p>PLEASE NOTE: skoah cannot perform this service if this form is incomplete.</p>
                    </div>
                    <form id="hook-form" onSubmit={handleSubmit(next)}>
                        <input type="hidden" {...register('form.form_type')} value={'glowwaiver'} />

                        <div className="row g-2 max-480">
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="date" className="form-label">date</label>
                                <input type="text" {...register('form.customer_date')} className="form-control form-control-gray datepicker" placeholder="date" id="date" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="shop" className="form-label">skoah shop</label>
                                <select id="shop" {...register('skoah_shop')} className="form-control form-select form-control-gray selectpicker" title="Select">
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                    <option data-content="<span class='auto-pin'>shop name</span><span class='auto-state'>city, state</span>">shop name</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label for="first" className="form-label">name</label>
                                <input type="text" {...register('form.customer_date')} className="form-control form-control-gray" placeholder="first" id="first" required />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 form-group mb-1">
                                <label className="form-label">&nbsp;</label>
                                <input type="text" {...register('form.last_name')} className="form-control form-control-gray" placeholder="last" id="last" required aria-label="last" />
                            </div>
                        </div>
                        <div className="row max-480">
                            <div className="col-12 col-sm-12 col-md-12 form-group mb-2">
                                <label for="birthday" className="form-label">can we have your autograph please?</label>
                                <div id="defaultSignature"></div>
                            </div>

                        </div>
                    </form>
                </div>


            </div>
        </>
    )
}